import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { CollectInfoSteps } from "../../lib/types/runtimeTypes";
import { VerificationStep } from "../../lib/types/types";

interface Props {
  step: VerificationStep;
}

// eslint-disable-next-line react/prop-types
const LoadingScreenMessage: React.FC<Props> = ({ step }) => (
  <div className="sid-loading-screen__message">
    {CollectInfoSteps.includes(step) && (
      <div className="sid-loading-screen__message-header">
        <FormattedHTMLMessage
          id="step.personalInfo.verifyingTitle"
          defaultMessage="Verifying your status"
        />
      </div>
    )}

    <div className="sid-loading-screen__message-subhead">
      <FormattedHTMLMessage
        id="step.personalInfo.verifyingSubtitle"
        defaultMessage="This should only take a minute"
      />
    </div>
  </div>
);

export default LoadingScreenMessage;
