import { logger } from "../utils/logger/logger";
import {
  VerificationStep,
  VerificationResponse,
  MockStep,
  SubSegment,
  ErrorId,
  Segment,
  SuccessResponse,
  ErrorResponse,
  ConsolationResponse,
  IDCheckLoopResponse,
} from "../types/types";
import { MockStepsEnum, SegmentToStepMap, VerificationStepsEnum } from "../types/runtimeTypes";
import { getOptions } from "../../options/options";
import {
  QUERY_STRING_STEP_OVERRIDE,
  QUERY_STRING_SUBSEGMENT_OVERRIDE,
  QUERY_STRING_ERRORID_OVERRIDE,
  QUERY_STRING_REWARD_CODE_OVERRIDE,
  QUERY_STRING_PREV_STEP_OVERRIDE,
  QUERY_STRING_SEGMENT_OVERRIDE,
  QUERY_STRING_REDIRECT_URL_OVERRIDE,
} from "../../constants";

import { allMockedResponses } from "../../serverMocks/mocks";
import { deepClone, getSafe } from "../utils/objects";

/**
 * Get a string value either from the query string (first priority) or from the options object (second priority)
 *
 * NOTICE: often the query param's property name differs from the options object's property name so be aware.
 * Going forward it's easier if they are the same. See QUERY_STRING_DOC_SELECTED for an example.
 */
export const getQueryOrOption = (propertyName: string): string | undefined => {
  try {
    const parsed: URLSearchParams = new URLSearchParams(window.location.search);

    if (parsed.get(propertyName)) {
      return parsed.get(propertyName);
    }
    const opts = getOptions();
    if (opts.hasOwnProperty(propertyName)) {
      return getOptions()[propertyName];
    }
  } catch (e) {
    logger.error(e);
  }
  return undefined;
};

// To load a specific step directly, visit e.g. localhost:1234/?mockStep=success
export const getRouteOverride = (
  response: VerificationResponse = undefined,
): MockStep | undefined => {
  try {
    const parsed: URLSearchParams = new URLSearchParams(window.location.search);

    let step;
    const segment = getSafe(() => response.segment);

    if (parsed.get(QUERY_STRING_STEP_OVERRIDE)) {
      step = parsed.get(QUERY_STRING_STEP_OVERRIDE) as VerificationStep;
    } else if (getOptions().mockStep) {
      step = getOptions().mockStep;
    }
    if (step === MockStepsEnum.collect) {
      return SegmentToStepMap[segment];
    }
    return step;
  } catch (e) {
    logger.error(e, "getRouteOverride");
  }
};

/**
 * @deprecated Use getQueryOrOption directly, instead
 * When loading a specific step, specify a segment
 */
const getSegmentOverride = (): Segment | undefined =>
  getQueryOrOption(QUERY_STRING_SEGMENT_OVERRIDE) as Segment;

/**
 * @deprecated Use getQueryOrOption directly, instead
 * When loading a specific step, specify a subsegment
 */
const getSubsegmentOverride = (): SubSegment | undefined =>
  getQueryOrOption(QUERY_STRING_SUBSEGMENT_OVERRIDE) as SubSegment;

/**
 * @deprecated Use getQueryOrOption directly, instead
 * When loading the error step, specify an errorId
 */
const getErrorIdOverride = (): ErrorId | undefined =>
  getQueryOrOption(QUERY_STRING_ERRORID_OVERRIDE) as ErrorId;

/**
 * @deprecated Use getQueryOrOption directly, instead
 * When loading the error step, specify an errorId
 */
export const getPreviousStepOverride = (): VerificationStep | undefined =>
  getQueryOrOption(QUERY_STRING_PREV_STEP_OVERRIDE) as VerificationStep;

/**
 * @deprecated Use getQueryOrOption directly, instead
 * Special case. The loading screen is not a step, so mocking it is a little different.
 */
export const isMockingLoading = (): boolean => {
  try {
    const parsed: URLSearchParams = new URLSearchParams(window.location.search);
    return (
      parsed.get(QUERY_STRING_STEP_OVERRIDE) === MockStepsEnum.loading ||
      getOptions()[QUERY_STRING_STEP_OVERRIDE] === MockStepsEnum.loading
    );
  } catch (e) {
    logger.error(e, "isMockingLoading");
    return false;
  }
};

/**
 * @deprecated Use getQueryOrOption directly, instead
 * Special case when mocking loading. To get text to show up, specify a previous step
 */
export const getMockedLoadingStep = (): VerificationStep => getPreviousStepOverride();

/**
 * @deprecated Use getQueryOrOption directly, instead
 * When loading the success step, specify a reward code
 */
const getRewardCodeOverride = (): string | undefined =>
  getQueryOrOption(QUERY_STRING_REWARD_CODE_OVERRIDE);

/**
 * @deprecated Use getQueryOrOption directly, instead
 * When loading the success step, specify a redirect URL
 */
const getRedirectUrlOverride = (): string | undefined =>
  getQueryOrOption(QUERY_STRING_REDIRECT_URL_OVERRIDE);

const getErrorStepMockResponseOverrides = (mockResponse: VerificationResponse) => {
  const mockResponseClone = deepClone(mockResponse) as ErrorResponse;
  const errorMock = getErrorIdOverride();
  const redirectUrlMock = getRedirectUrlOverride();

  if (errorMock) {
    mockResponseClone.errorIds = [errorMock];
  }

  if (redirectUrlMock) {
    mockResponseClone.redirectUrl = redirectUrlMock;
  }
  return mockResponseClone;
};

const getSuccessStepMockResponseOverrides = (mockResponse: VerificationResponse) => {
  const mockResponseClone = deepClone(mockResponse) as SuccessResponse;
  const rewardCodeMock = getRewardCodeOverride();
  const redirectUrlMock = getRedirectUrlOverride();

  if (rewardCodeMock) {
    mockResponseClone.rewardCode = rewardCodeMock;
  }

  if (redirectUrlMock) {
    mockResponseClone.redirectUrl = redirectUrlMock;
  }
  return mockResponseClone;
};

const getConsolationStepMockResponseOverrides = (mockResponse: VerificationResponse) => {
  const mockResponseClone = deepClone(mockResponse) as ConsolationResponse;
  const consolationRewardCodeMock = getRewardCodeOverride();

  if (consolationRewardCodeMock) {
    mockResponseClone.consolationRewardCode = consolationRewardCodeMock;
  }

  return mockResponseClone;
};

const getIDCheckLoopStepMockResponseOverrides = (mockResponse: VerificationResponse) => {
  const mockResponseClone = deepClone(mockResponse) as IDCheckLoopResponse;
  return mockResponseClone;
};

/**
 * Based on page request query params, return a fake response to allow direct access to any step.
 *
 * @param overriddenStep
 * @param serverResponse The actual serverResponse to acquire certain pieces of info from.
 */
export const getOverriddenMock = async (
  overriddenStep: MockStep,
  serverResponse?: VerificationResponse,
): Promise<VerificationResponse> => {
  let mockResponse = getOptions().mockResponse || allMockedResponses[overriddenStep];

  if (overriddenStep && mockResponse) {
    logger.info(`getOverriddenMock: mocking ${overriddenStep} response`);

    switch (overriddenStep) {
      case VerificationStepsEnum.error:
        mockResponse = getErrorStepMockResponseOverrides(mockResponse);
        break;

      case VerificationStepsEnum.success:
        mockResponse = getSuccessStepMockResponseOverrides(mockResponse);
        break;

      case VerificationStepsEnum.consolation:
        mockResponse = getConsolationStepMockResponseOverrides(mockResponse);
        break;

      case VerificationStepsEnum.idCheckLoop:
        mockResponse = getIDCheckLoopStepMockResponseOverrides(mockResponse);
        break;

      default:
        break;
    }
  } else {
    mockResponse = null;
    logger.info(`
            getOverriddenMock: not mocking response.
            You may mock a step response by adding things like ?mockStep=success to your route.
            ${overriddenStep}
        `);
  }

  if (mockResponse !== null && serverResponse) {
    await serverResponse;
    mockResponse.segment = serverResponse.segment;
    mockResponse.subSegment = serverResponse.subSegment;
    if (getSegmentOverride()) {
      mockResponse.segment = getSegmentOverride();
      logger.info(`getOverriddenMock: mocking segment '${mockResponse.segment}'`);
    }
    if (getSubsegmentOverride()) {
      mockResponse.subSegment = getSubsegmentOverride();
      logger.info(`getOverriddenMock: mocking subSegment '${mockResponse.subSegment}'`);
    }
  }
  return Promise.resolve(mockResponse);
};
