import { OrganizationSearchResp, Organization, AddSchoolRequestViewModel } from "../types/types";
import { logger } from "../utils/logger/logger";
import { GetJson, PostJson } from "./Network";
import { getAddSchoolRequestUrl } from "./ApiUrls";
import ResponseTimeLogger from "../utils/responseTimeLogger/ResponseTimeLogger";

const country = "country";
const name = "name";

type FetchOrganizationResponse = Promise<OrganizationSearchResp | undefined>;

export async function fetchProgramOrganizations(
  orgSearchUrl: string,
  searchTerm: string,
  countryCode?: string,
  searchTags?: string,
): FetchOrganizationResponse {
  if (!orgSearchUrl) {
    return Promise.resolve([]);
  }

  const searchUrl = new URL(orgSearchUrl);
  const { origin, pathname, search } = searchUrl;
  const searchParams = new URLSearchParams(search);

  searchParams.set(name, searchTerm);

  if (countryCode) {
    searchParams.set(country, countryCode);
  }
  if (searchTags) {
    searchParams.set("tags", searchTags);
  }

  const url = `${origin}${pathname}?${searchParams.toString()}`;

  return fetchOrganization(url);
}

async function fetchOrganization(url: string): FetchOrganizationResponse {
  try {
    const responseEntries = (await GetJson(url)) as OrganizationSearchResp;
    return responseEntries.map((entry) => mapOrg(entry)) as OrganizationSearchResp;
  } catch (e) {
    logger.error(e, "failed to fetchOrganization");
  }
}

function mapOrg(sourceOrg: Organization): Organization {
  return {
    id: sourceOrg.id,
    idExtended: sourceOrg.idExtended === undefined ? sourceOrg.id.toString() : sourceOrg.idExtended,
    name: sourceOrg.name,
    country: sourceOrg.country,
  };
}

export const fetchRequestOrganizations = async (url: string): Promise<Object> => {
  const timeLog = new ResponseTimeLogger(url);
  const response = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "GET",
  });
  if (response.status >= 400) {
    throw new Error("An error occurred");
  }
  let responseData: any;
  try {
    responseData = await response.json();
  } catch (error) {
    // Handle 204 response
    responseData = Promise.resolve({});
  } finally {
    timeLog.logNow();
    logger.info("Response received", responseData);
  }
  return responseData;
};

export async function addSchool(request: AddSchoolRequestViewModel): Promise<Object> {
  return (await PostJson(getAddSchoolRequestUrl(), request)) as Promise<Object>;
}
