import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import {
  FormFieldComponentProps,
  VerificationServiceProps,
  Country,
  Locale,
  FormSelectChoice,
} from "../../../lib/types/types";
import { getSafe } from "../../../lib/utils/objects";
import { getOptions } from "../../../options/options";
import { getLocaleSafely } from "../../../lib/intl/intl";
import { getOrgSearchCountryTags } from "../../../lib/ProgramTheme/programThemeGetters";
import { FormFieldTypeahead } from "../FormFieldTypeahead/FormFieldTypeahead";

export const TeacherSchool = ({
  value,
  isErrored,
  onChange,
  verificationService,
  intl,
  placeholder = "",
}: FormFieldComponentProps & VerificationServiceProps) => {
  const { programId, viewModel, verificationResponse } = verificationService;
  const htmlId = "teacher-school";
  const locale: Locale = getLocaleSafely(viewModel, verificationResponse);
  const urlAddSchoolForm = (getOptions().urlAddSchoolForm || "").trim();
  const country = getSafe<Country>(() => verificationService.viewModel.countryChoice.value);
  const searchTags = getOrgSearchCountryTags(verificationService.programTheme, country);

  return (
    <FormFieldTypeahead
      fieldId={htmlId}
      value={value}
      country={country}
      onChange={(choice: FormSelectChoice) => onChange(choice)}
      placeholder={
        placeholder ||
        intl.formatHTMLMessage({
          id: "schoolNamePlaceholder",
          defaultMessage: "School name",
        })
      }
      programId={programId}
      label={<FormattedHTMLMessage id="schoolName" defaultMessage="School name" />}
      errorMessage={
        isErrored && (
          <FormattedHTMLMessage
            id="errorId.invalidOrganization"
            defaultMessage="You must select an organization from the list"
          />
        )
      }
      verificationService={verificationService}
      showPlaceholderAndHideLabel={!!placeholder}
      orgSearchTags={searchTags}
      locale={locale}
      urlAddSchoolForm={urlAddSchoolForm}
      isRequired
    />
  );
};

export const TeacherSchoolComponent = injectIntl(TeacherSchool);
