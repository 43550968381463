import React from "react";
import { dashed } from "../../../lib/utils/strings";
import { FormFieldLayout } from "../FormFieldLayout/FormFieldLayout";
import { TypeaheadComponent, TypeaheadProps } from "../../FormInputs/Typeahead/TypeaheadComponent";
import { Country, VerificationService } from "../../../lib/types/types";
import { getOptions } from "../../../options/options";

// Extend TypeaheadProps to pass along things like required, onKeyUp, etc.
// Omit errorId, isErrored, etc because those are handled within this component
export interface FormFieldTypeaheadComponentProps
  extends Omit<TypeaheadProps, "inputHtmlId" | "id" | "errorId" | "isErrored"> {
  fieldId: string; // should be camelCase
  verificationService: VerificationService;
  label: React.ReactNode; // Label is always required for screen readers
  showPlaceholderAndHideLabel?: boolean;
  errorMessage?: React.ReactNode;
  explanation?: string | JSX.Element;
  country?: Country;
}

/**
 * Generic typeahead field component.
 * In the JSLIB, a field has a label, inline error message, and an input of some kind.
 */
export const FormFieldTypeahead = ({
  fieldId,
  value,
  verificationService,
  onChange,
  placeholder,
  label,
  errorMessage,
  showPlaceholderAndHideLabel = false,
  isRequired = true,
  explanation = "",
  country,
  orgSearchTags,
  locale,
  urlAddSchoolForm,
  programId,
}: FormFieldTypeaheadComponentProps) => {
  const labelHtmlId = `sid-${dashed(fieldId)}`;
  const errorHtmlId = `sid-${dashed(fieldId)}-error`;
  return (
    <FormFieldLayout
      fieldId={fieldId}
      label={label}
      errorMessage={errorMessage}
      showPlaceholderAndHideLabel={showPlaceholderAndHideLabel}
      isRequired={isRequired}
      explanation={explanation}
      containerId={`${labelHtmlId}-id`}
    >
      <TypeaheadComponent
        className={errorMessage ? "sid-text-input--error" : ""}
        countryCode={country}
        minimumSearchValueLength={getOptions().minimumOrganizationSearchLength}
        onChange={onChange}
        placeholder={placeholder}
        programId={programId}
        value={value}
        inputHtmlId={labelHtmlId}
        openOrgSearchEnabled={verificationService.programTheme.openOrgSearchEnabled}
        orgSearchUrl={verificationService.programTheme.config.orgSearchUrl}
        orgSearchTags={orgSearchTags}
        locale={locale}
        urlAddSchoolForm={urlAddSchoolForm}
        isRequired={isRequired}
        isErrored={!!errorMessage}
        ariaDescribedBy={errorMessage ? errorHtmlId : ""}
      />
    </FormFieldLayout>
  );
};

// DO NOT INJECT INTL. Localize your strings in the calling component and pass them as props to this component.
export const FormFieldTypeaheadComponent = FormFieldTypeahead;
