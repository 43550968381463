import {
  DEFAULT_CDN_BASE_URL,
  QUERY_STRING_INSTALL_PAGE_URL,
  QUERY_STRING_INSTALL_TYPE,
  QUERY_STRING_LAYOUT,
} from "../../constants";
import { getOptions } from "../../options/options";
import { RewardDisplayEnum } from "../types/runtimeTypes";
import { DatabaseId, Locale, VerificationStep } from "../types/types";
import { logger } from "../utils/logger/logger";
import { ensureTrailingSlash } from "../utils/routing/Url";

export const getVerificationUrl = () =>
  `${getOptions().restApi.serviceUrl}${getOptions().restApi.resources.verification}`;
export const getVerificationStatusUrl = (verificationId: DatabaseId) =>
  `${getVerificationUrl()}${verificationId}`;
export const getNewVerificationRequestUrl = () => getVerificationUrl();
export const getProgramThemeUrl = (programId: DatabaseId, locale: Locale = null) => {
  const baseUrl = `${getOptions().restApi.serviceUrl}${
    getOptions().restApi.resources.program.base
  }${programId}/${getOptions().restApi.resources.program.theme}`;
  if (locale) {
    return `${baseUrl}?locale=${locale}`;
  }
  return `${baseUrl}`;
};
export const getAddSchoolRequestUrl = () =>
  `${getOptions().restApi.serviceUrl}rest/v2/organization/addSchool/`;

export const getNewSmsCodeResendUrl = (verificationId: DatabaseId) =>
  `${getOptions().restApi.serviceUrl}rest/v2/verification/${verificationId}/step/smsLoop/retry`;
export const getNewEmailCodeResendUrl = (verificationId: DatabaseId) =>
  `${getOptions().restApi.serviceUrl}rest/v2/verification/${verificationId}/step/emailLoop/retry`;

export const getFieldsToCollectUrl = (verificationId: DatabaseId, stepName: VerificationStep) =>
  `${getOptions().restApi.serviceUrl}rest/v2/verification/${verificationId}/${stepName}/fields`;

export const getDocTypeForCountriesUrl = (verificationId: DatabaseId, countries: string[]) =>
  `${
    getOptions().restApi.serviceUrl
  }rest/v2/verification/${verificationId}/step/idCheckLoop/getDocumentTypesForCountries?countries=${countries}`;

export const getBarcodeUrl = (
  verificationId: DatabaseId,
  rewardKey: string,
  symbology: RewardDisplayEnum,
) =>
  `${
    getOptions().restApi.serviceUrl
  }rest/v2/verification/${verificationId}/barcode?rewardKey=${rewardKey}&symbology=${symbology}`;

/**
 * Get the version that was actually used
 * Comes from the contents of sheerid.js. The build process adds it there.
 * e.g. 1.59.0 or 1.46.0-alpha.575
 */
export const getPublishedVersion = (): string => window.sheerIdPubV;

/**
 * Replace a CDN URL's dist tag with a more specific version
 */
export const urlMajorToSpecific = (cdnUrl: string, specificVersion: string): string =>
  ensureTrailingSlash(
    specificVersion ? cdnUrl.replace(/@(?!sheerid)[^/]*/gi, `@${specificVersion}`) : cdnUrl,
  );

export const getCurrentUrl = (): string => document.location.href;

export const getInstallPageUrl = (): string | undefined => {
  try {
    const parsed: URLSearchParams = new URLSearchParams(window.location.search);

    if (parsed.get(QUERY_STRING_INSTALL_PAGE_URL)) {
      return decodeURIComponent(parsed.get(QUERY_STRING_INSTALL_PAGE_URL));
    }
    if (getOptions()[QUERY_STRING_INSTALL_PAGE_URL]) {
      return getOptions()[QUERY_STRING_INSTALL_PAGE_URL];
    }
  } catch (e) {
    logger.error(e);
  }

  return getCurrentUrl();
};

enum InstallType {
  landingPage = "landing_page",
  cdnNoIframe = "cdn_no_iframe",
}
const QUERY_VALUE_LANDING = "landing";

export const getInstallType = (): string => {
  try {
    const parsed: URLSearchParams = new URLSearchParams(window.location.search);

    if (parsed.get(QUERY_STRING_INSTALL_TYPE)) {
      return decodeURIComponent(parsed.get(QUERY_STRING_INSTALL_TYPE));
    }

    if (parsed.get(QUERY_STRING_LAYOUT) === QUERY_VALUE_LANDING) {
      return InstallType.landingPage;
    }
  } catch (e) {
    logger.error(e);
  }

  return window.sheerIdInstallType || InstallType.cdnNoIframe;
};

/**
 * Get the canonical URL from which to load all other jslib files
 */
export const getSheerIdScriptBasePath = (): string => {
  let basePath: string;
  try {
    const scriptSrc = window.sheerIdCurrentScript.getAttribute("src");
    basePath = scriptSrc.substr(0, scriptSrc.lastIndexOf("/")); // drop the filename
  } catch (e) {
    if (document.location.hostname !== "localhost") {
      logger.error(e);
    }
  }

  if (!basePath) {
    if (document.location.hostname === "localhost") {
      basePath = document.location.origin;
    } else {
      basePath = DEFAULT_CDN_BASE_URL;
      logger.error("No window.sheerIdCurrentScript, is document.currentScript unsupported?");
    }
  }

  return ensureTrailingSlash(urlMajorToSpecific(basePath, getPublishedVersion()));
};

/**
 * Get the version that was _requested_ via script src
 * e.g. alpha or 1 or 1.59 or 1.59.0
 */
export const getRequestedVersion = (): string => {
  let requestedVersion;
  try {
    requestedVersion = /jslib(\-nightly)?\@[a-zA-Z0-9\.\-]+/gi
      .exec(getSheerIdScriptBasePath())[0]
      .split("@")[1]
      .trim();
  } catch (e) {
    logger.error(e);
  }

  return requestedVersion;
};
