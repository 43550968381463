import React from "react";
import { dashed } from "../../../lib/utils/strings";
import { FieldLabel } from "../../FieldLabel";

export interface FormFieldLayoutProps {
  fieldId: string; // should be camelCase
  label: React.ReactNode;
  isRequired: boolean;
  showPlaceholderAndHideLabel?: boolean;
  errorMessage?: React.ReactNode;
  children?: React.ReactNode;
  explanation?: string | JSX.Element;
  containerId?: string;
}

/**
 * In the JSLIB, a FormField has a label, inline error message, and children like a text input
 * This component provides consistent structure, classnames and some accessibility considerations for all FormFields
 */
export const FormFieldLayout = ({
  fieldId,
  label,
  errorMessage,
  isRequired,
  showPlaceholderAndHideLabel = false,
  children,
  explanation = "",
  containerId = undefined,
}: FormFieldLayoutProps) => {
  const labelIntlId = `${fieldId}Label`;
  const labelHtmlId = `sid-${dashed(labelIntlId)}`;
  const errorHtmlId = `sid-${dashed(fieldId)}-error`;
  const containerClassName = containerId || `sid-${dashed(fieldId)}`;
  return (
    <div className={`sid-field ${containerClassName}`}>
      <div className="sid-l-space-top-md" />

      <FieldLabel
        text={label}
        isRequired={isRequired}
        htmlForLabel={`sid-${dashed(fieldId)}`}
        id={labelHtmlId}
        displayClasses={`${explanation ? "sid-field__label-with-explanation" : ""}${
          explanation && showPlaceholderAndHideLabel ? " " : ""
        }${showPlaceholderAndHideLabel ? "sid-h-screen-reader-only" : ""}`}
      >
        {explanation && explanation}
      </FieldLabel>

      {children}

      {errorMessage && (
        <div className="sid-field-error" id={errorHtmlId}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};
