import React from "react";
import { dashed } from "../../../lib/utils/strings";
import { FormFieldLayout } from "../FormFieldLayout/FormFieldLayout";
import {
  InputSelectComponent,
  InputSelectProps,
} from "../../FormInputs/InputSelect/InputSelectComponent";
import { SortByLabel } from "../../../lib/utils/structures/FormSelectChoice";

// Extend InputSelectProps to pass along things like required, onKeyUp, etc.
// Omit errorId, isErrored, etc because those are handled within this component
export interface FormFieldInputSelectComponentProps
  extends Omit<InputSelectProps, "inputHtmlId" | "id" | "errorId" | "isErrored"> {
  fieldId: string; // should be camelCase
  label: React.ReactNode; // Label is always required for screen readers
  showPlaceholderAndHideLabel?: boolean;
  errorMessage?: React.ReactNode;
  explanation?: string | JSX.Element;
  containerId?: string;
  refId?: string;
}

/**
 * Generic input select field component.
 * In the JSLIB, a field has a label, inline error message, and an input of some kind.
 */
export const FormFieldInputSelect = ({
  fieldId = undefined,
  value,
  onChange,
  placeholder,
  label,
  errorMessage,
  showPlaceholderAndHideLabel = false,
  isRequired = true,
  explanation = "",
  autoFocus,
  options,
  containerId = undefined,
  onKeyDown,
  refId = "status",
}: FormFieldInputSelectComponentProps) => {
  const labelHtmlId = `sid-${dashed(fieldId)}`;
  const errorHtmlId = `sid-${dashed(fieldId)}-error`;
  return (
    <FormFieldLayout
      fieldId={fieldId}
      label={label}
      errorMessage={errorMessage}
      showPlaceholderAndHideLabel={showPlaceholderAndHideLabel}
      isRequired={isRequired}
      explanation={explanation}
      containerId={containerId}
    >
      <InputSelectComponent
        autoFocus={autoFocus}
        fieldId={refId}
        inputId={labelHtmlId}
        fieldLabelId={`${labelHtmlId}-label`}
        isErrored={!!errorMessage}
        options={SortByLabel(options)}
        onChange={onChange}
        value={options.find((option) => option.value === (value && value.value))}
        placeholder={placeholder}
        isRequired={isRequired}
        ariaDescribedBy={errorMessage ? errorHtmlId : ""}
        onKeyDown={onKeyDown}
      />
    </FormFieldLayout>
  );
};

// DO NOT INJECT INTL. Localize your strings in the calling component and pass them as props to this component.
export const FormFieldInputSelectComponent = FormFieldInputSelect;
