/* tslint:disable:max-line-length */
import { DefaultMessages } from "../../types/types";
import { countryMessages } from "./countryMessages";
import { localesMessages } from "./localesMessages";
import { stateMessages } from "./stateMessages";

export const defaultMessages: DefaultMessages = {
  activeDutyStartDate: "Active Duty Start Date",
  address: "Address",
  addressPlaceholder: "Address",
  birthDate: "Date of birth",
  branchOfService: "Branch of service",
  branchOfServicePlaceholder: "Branch Of Service",
  changeLanguage: "Change language",
  city: "City",
  cityPlaceholder: "City",
  company: "Company",
  companyPlaceholder: "Company",
  copied: "Copied",
  country: "Country",
  countryPlaceholder: "Country",
  countries: countryMessages,
  dateTime: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    month: "Month",
    day: "Day",
    year: "Year",
    "2_MIN": "2 minutes",
    "20_MIN": "20 minutes",
    "2_HRS": "2 hours",
    "24_HRS": "24 hours",
    "2_DAY": "2 days",
    "3_DAY": "3 days",
    A_FEW_MINUTES: "a few minutes",
    A_HALF_HOUR: "half an hour",
    A_FEW_HOURS: "a few hours",
    A_FEW_DAYS: "a few days",
  },
  dischargeDate: "Discharge date",
  driverLicenseNumber: "Driver license number",
  driverLicenseNumberPlaceholder: "Driver license number",
  driverLicenseState: "Driver license state",
  ebtCardNumber: "EBT Card Number",
  emailAddress: "Email address",
  emailAddressPlaceholder: "Email address",
  instant: "Instant",
  optIn:
    "Yes, send me {company} marketing communications about exclusive sales, special offers, latest products and more",
  phoneNumber: "Mobile Number",
  phoneNumberExplanation: "Optional - get text notifications of status updates",
  phoneNumberWarnMessage1:
    "By submitting this form with a mobile number, you consent to receive SMS/text messages for verification purposes. Standard phone carrier text messaging (SMS) and data rates may apply.",
  phoneNumberWarnMessage2:
    "You authorize your mobile service provider to disclose your mobile number and certain account information to SheerID, solely for identity verification and to avoid fraud.",
  phoneUsPlaceholder: "555-555-5555",
  error: "Error",
  limitExceededError: "Verification Limit Exceeded",
  firstName: "First name",
  firstNamePlaceholder: "First name",
  memberId: "ID Number",
  memberIdPlaceholder: "ID Number",
  optional: "Optional",
  howDoesReviewWork: "How does review work?",
  howDoesVerifyingWork: "How does verifying work?",
  informationTransferredToUS:
    "Your information will be transferred to the United States and will be treated according to SheerID’s privacy policy.",
  lastName: "Last name",
  lastNamePlaceholder: "Last name",
  loading: "Loading...",
  locales: localesMessages,
  militaryStatus: "Military status",
  noOptions: "No options",
  lowRewardPool: "&#128293; Only {offersRemaining} offers left!",
  personalInformation: "Personal Information",
  personalOrSchoolIsFine: "Personal or school email is fine",
  postalCode: "Postal code",
  postalCodeExplanation: "Use the location on file with your licensing body",
  postalCodePlaceholder: "Postal code",
  poweredBy: "Verification services powered by SheerID",
  proceed: "Proceed",
  requestSchool: "Don't see your school? Request to have it added.",
  requiredFields: "All fields are required",
  rewardCodeEmailNotification:
    "We have emailed you a copy so you can use your new code now or later. Look for the email from verify@sheerid.com.",
  school: "School",
  schoolName: "School",
  schoolNamePlaceholder: "School",
  sheeridFaqs: "SheerID FAQs",
  privacyPolicy: "Privacy Policy",
  smsCodePlaceholder: "SMS Code",
  state: "State",
  statePlaceholder: "State",
  states: stateMessages,
  status: "Status",
  statusPlaceholder: "Status",
  streetAddress: "Street Address",
  universityName: "University name",
  verifyAndContinue: "Verify and continue",
  verificationPurposesOnly: "Used for verification purposes only",
  verifyMyStudentStatus: "Verify My Student Status",
  verifyMyTeacherStatus: "Verify My Teacher Status",
  verifyMyMembershipStatus: "Verify My Membership Status",
  verifyMyMilitaryStatus: "Verify My Military Status",
  verifyMyIdentityStatus: "Verify My Identity",
  verifyMyMedicalProfessionalStatus: "Verify My Medical Professional Status",
  verifyMyEmploymentStatus: "Verify My Employee Status",
  verifyMyLicensedProfessionalStatus: "Verify My Licensed Professional Status",
  verifyMyMoverStatus: "Verify My New Mover Status",
  verifyMyLowIncomeStatus: "Verify my status",
  VETERAN: "Military Veteran or Retiree",
  MILITARY_RETIREE: "Military Retiree",
  RESERVIST: "Reservist or National Guard",
  ACTIVE_DUTY: "Active Duty",
  MILITARY_FAMILY: "Military Family",
  GOLD_STAR_FAMILY: "Gold Star Family",
  FIREFIGHTER: "Firefighter",
  POLICE: "Police",
  EMT: "EMT",
  SEARCH_AND_RESCUE: "Search and Rescue",
  DOCTOR: "Doctor",
  NURSE: "Nurse",
  DENTIST: "Dentist",
  PHARMACIST: "Pharmacist",
  OTHER_HEALTH_WORKER: "Other Health Worker",
  HOME_BUYER: "Home Buyer",
  OTHER_MOVER: "Other Mover",
  SNAP_BENEFITS: "Snap Benefits Recipient",
  OTHER_GOVERNMENT_ASSISTANCE: "Other Government Assistance Recipient",
  LICENSED_COSMETOLOGIST: "Licensed Cosmetologist",
  LICENSED_REAL_ESTATE_AGENT: "Licensed Real Estate Agent",
  CHILD_CARE_WORKER: "Child Care Worker",
  LIBRARIAN: "Librarian",
  INTERIOR_DESIGNER: "Interior Designer",
  ARCHITECT: "Architect",
  GENERAL_CONTRACTOR: "General Contractor",
  VETERINARIAN: "Veterinarian",
  organization: "Organization",
  organizationPlaceholder: "Organization",
  lowIncomeOrganization: "Government Assistance Program",
  lowIncomeOrganizationPlaceholder: "Government Assistance Program",
  verifyMyFirstResponderStatus: "Verify My First Responder Status",
  tryAgain: "Try again",
  errorId: {
    // These are the messages to show to the user for a given error ID. The error step may have a unique title, which are not these. See `steps.error.errorId.???.title` for that
    activeDutyStartDateBeforeBirthDate: "Start date must be after birth date",
    apiRateLimitExceeded: "Unable to process your request at this time, please try again later",
    dischargeDateBeforeBirthDate: "Discharge Date before birthdate",
    docReviewLimitExceeded: "Document review limit exceeded",
    inactiveProgram:
      "Due to tremendous demand, the offer is no longer available. Please check tomorrow to see if it is available.",
    expiredEmailLoopToken:
      "We are sorry, the page you requested cannot be found. The URL may be incorrect or the page you're looking for is no longer available.",
    expiredProgram:
      "Due to tremendous demand, the offer is no longer available. Please check tomorrow to see if it is available.",
    expiredSMSCode: "Verification code expired",
    expiredVerification: "This verification is expired. Please submit a new verification.",
    failedToFetch: "Failed HTTP request",
    fraudRulesReject:
      "We're unable to verify you using the information submitted. Please check your information for accuracy, and only use your own mobile device or computer.<br><br>Still having trouble? <a href=\"{feedbackUrl}&pid={programId}&locale={locale}\">Contact SheerID's support team</a> to confirm your identity.",
    futureBirthDate: "Future birthdate",
    futureDischargeDate: "Future discharge date",
    futureActiveDutyStartDate: "Future start date",
    incorrectVerificationOverrideCodeAttemptLimitExceeded: "Override code limit exceeded",
    internalServerError: "Internal server error",
    invalidActiveDutyStartDate: "Invalid start date",
    invalidAddress: "Invalid address",
    invalidApiToken: "Provided API token was invalid or empty",
    invalidBirthDate: "Invalid birth date",
    invalidCity: "Invalid city",
    invalidCountry: "Invalid country",
    invalidDischargeDate: "Invalid discharge date",
    invalidDocUploadToken: "Provided document upload token is not valid",
    invalidEbtCardNumber: "Invalid EBT Card Number",
    invalidEmail: "Invalid email",
    invalidEmailLoopToken:
      "We are sorry, the page you requested cannot be found. The URL may be incorrect or the page you're looking for is no longer available.",
    invalidFileSizeEmpty: "One or more files is empty",
    invalidFileSizeMax: "One or more files is too large (max: 10MB)",
    invalidFirstName: "Invalid first name",
    invalidFirstResponderStatus: "Invalid first responder status",
    invalidLastName: "Invalid last name",
    invalidMemberId: "Invalid ID Number",
    invalidMilitaryStatus: "Invalid military status",
    invalidNumberOfFiles: "Maximum number of files exceeded (max: 5)",
    invalidOptIn: "Must be selected to continue",
    invalidOrganization: "You must select an organization from the list",
    invalidOverrideCode: "Invalid Override Code",
    invalidPhoneNumber: "Invalid mobile number",
    invalidPostalCode: "Invalid postal code",
    invalidProgram: "Invalid program",
    invalidRequest: "Invalid request",
    invalidSMSCode: "Verification code could not be validated",
    invalidSocialSecurityNumber: "Invalid SSN",
    invalidState: "Invalid state",
    invalidStatus: "Invalid status",
    invalidStatuses: "Invalid status",
    invalidDriverLicenseNumber: "Invalid driver license number",
    invalidStep: "Invalid step",
    missingRequiredMetadata:
      "You must be logged in to verify. Log in to your account then try again.",
    marketConsentRequired: "Market consent required",
    maxMetadataLengthExceeded: "Maximum length of metadata value exceeded",
    maxMetadataValuesExceeded: "Maximum number of metadata values exceeded",
    maxSMSCodeLimitExceeded: "",
    incorrectSMSCodeAttemptLimitExceeded: "",
    noProgram: "No program found",
    noRemainingRewardCodes:
      "Due to tremendous demand, the offer is no longer available. Please check tomorrow to see if it is available.",
    notApproved: "Not approved",
    notFound: "Not found",
    noValidFiles: "No valid files",
    noVerification: "No verification found",
    outsideAgePerson: "Birthdate is not eligible for this offer.",
    requestTimeout: "Request timeout",
    simulatedError: "This is a simulated error",
    unauthorizedDomain: "Unauthorized domain",
    unauthorizedAccountStatus:
      "This offer has been temporarily discontinued. Please contact the brand’s customer support for more information.",
    underagePerson: "Persons under age 16 are not eligible for this offer.",
    unknownError: "Unknown Error",
    unsupportedDocMimeType: "One or more files is an unsupported format",
    verificationLimitExceeded:
      "We're glad you're enthusiastic, but it looks like you've already redeemed or attempted to redeem this offer.",
  },
  step: {
    docUpload: {
      acceptableUploadExamples: "Acceptable examples include:",
      acceptedTypes: "File must be .jpg .jpeg .png .pdf .gif or .bmp",
      addFile: "Add file",
      cancelButtonLabel: "Cancel",
      fileInstructions:
        'Drag &amp; drop your files here or <span class="sid-dropzone-wrap--link">browse</span>',
      submitButtonLabel: "Continue",
      rejectedSubtitle: "We were unable to confirm your eligibility due to:",
      nameChanged:
        "If your name has changed since the document was issued, upload a 2nd document showing your official name change.",
      allowedDocuments: "Please only upload documents you are legally allowed to provide.",
      cancelDocUploadLabel: "Click here if you are unable to verify with a document",
      rejectionReasons: {
        DOCUMENT_LIKELY_FRAUD:
          "The document is insufficient for establishing eligibility for this offer.",
        DOCUMENT_UNSUPPORTED:
          "The document is not official or on the list of accepted document types.",
        DOCUMENT_UNREADABLE:
          "The document was unreadable due to poor image quality. Please make sure you have a clearly legible image that's bright and crisp, and try submitting again.",
        DOCUMENT_EXPIRED:
          "The document has already expired or was issued too far in the past. Please upload a more recent document.",
        DOCUMENT_TOO_FUTURE:
          "The date shown in the document is too far in the future. Please upload a more recent document, which proves your current status.",
        MISSING_DOCUMENT_DATE:
          "A current date is missing. Please upload an official, dated document that proves your current status.",
        INSUFFICIENT_DOCUMENT_DATE:
          "The date(s) shown in the document are insufficient to prove your current status. Please upload a document that includes a recent month and year.",
        INSUFFICIENT_STATUS:
          "The document does not prove you are a status eligible for this offer.",
        MISMATCH_STATUS:
          "The status on the document does not match the status you entered on your verification form.",
        INELIGIBLE_JOB_TITLE: "The job title shown in the document is not eligible for this offer.",
        MISSING_INFORMATION_BIRTHDATE: "Your birthdate is missing.",
        MISMATCH_BIRTHDATE:
          "The birthdate on the document does not match the birthdate you entered on your verification form.",
        OUT_OF_RANGE_BIRTHDATE: "Your birthdate is outside the acceptable range of this offer.",
        MISSING_PERSON_LAST_NAME: "Your last name is missing.",
        MISMATCH_PERSON_LAST_NAME:
          "The last name on the document does not match the last name you entered on your verification form.",
        MISSING_PERSON_FIRST_NAME: "Your first name is missing.",
        MISMATCH_PERSON_FIRST_NAME:
          "The first name on the document does not match the first name you entered on your verification form.",
        MISSING_INFORMATION_ORGANIZATION_NAME: "Your organization name is missing.",
        MISMATCH_ORGANIZATION_NAME:
          "The organization name on the document does not match the organization name you entered on your verification form.",
        MISMATCH_ORGANIZATION_COUNTRY:
          "The country shown in your document does not match the country you entered on your verification form",
        MISSING_CUSTOM_VALUE:
          "The document is missing information needed to establish your eligibility for this offer.",
        MISMATCH_CUSTOM_VALUE:
          "Some information shown in the document does not match what you entered in the verification form.",
        INSUFFICIENT_CUSTOM_VALUE:
          "The document is missing information needed to establish your eligibility for this offer.",
        DOCUMENT_VALID_OTHER:
          "The document submitted is not able to establish eligibility for this offer.",
        DOCUMENT_STATUS_OTHER:
          "The document submitted shows a status that is not sufficient for establishing eligibility for this offer.",
        DOCUMENT_DATE_OTHER:
          "The document submitted does not meet the date requirements for establishing eligibility for this offer.",
        PERSON_BIRTHDATE_OTHER:
          "The date of birth shown in the document submitted did not match with what was provided on the verification form.",
        PERSON_LAST_NAME_OTHER:
          "The last name shown in the document submitted did not match with what was provided on the verification form.",
        PERSON_FIRST_NAME_OTHER:
          "The first name shown in the document submitted did not match with what was provided on the verification form.",
        ORGANIZATION_NAME_OTHER:
          "The organization name shown in the document submitted was something other than what was provided on the verification form.",
        CUSTOM_VALUE_OTHER:
          "The document submitted is missing a requirement for establishing eligibility for this offer.",

        // deprecated
        DOCUMENT_PASSWORD_PROTECTED: "We are unable to accept password protected documents.",
        DOCUMENT_HANDWRITTEN: "We cannot accept handwritten documents.",
        MISSING_INFORMATION:
          "The document you submitted does not provide all of the information necessary to determine eligibility for this offer.",
        MISSING_INFORMATION_PERSON_NAME:
          "The document you submitted is missing a first and/or last name.",
        DOCUMENT_OUTDATED:
          "The document you submitted is missing an issue date or was issued too far in the past.",
        MISMATCH_PERSON_NAME:
          "The information on the document you submitted does not match the first and/or last name submitted on the verification form.",
        MISMATCH_ORGANIZATION:
          "The organization name on the document(s) you submitted does not match the organization you listed on the verification form.",
        INELIGIBLE_ORGANIZATION:
          "The organization on the document you submitted is not eligible for this offer.",
        MISSING_INFORMATION_UNIVERSITY_ENROLLMENT:
          "The document you submitted does not prove enrollment in the current term.",
        INELIGIBLE_PERSON_HIGH_SCHOOL_STUDENT:
          "High School Students are not eligible for this offer.",
        MISSING_INFORMATION_AFFILIATION_US_ARMED_FORCES:
          "The document you submitted is does not show affiliation to an eligible branch of the United States Armed Forces.",
        MISMATCH_COMPANY_NAME_OR_ADDRESS:
          "The company name or address in your document does not match what you entered on the verification form.",
        PAYSTUB_OUTDATED_LAST_30_DAYS:
          "Please upload a paystub that was issued within the last 30 days.",
        DOCUMENT_OUTDATED_FACULTY:
          "The document you submitted does not prove employment in the current academic year.",
        MISSING_OR_MISMATCH_JOB_TITLE:
          "The job title on the document you submitted is missing or does not match what you entered on the verification form.",
        OTHER_CONTACT_US:
          "We are having problems verifying your eligibility for the offer. Please contact SheerID customer service for help: customerservice@sheerid.com",
        PERSON_FIELD_MISMATCH:
          "A field in the document provided did not match with what was provided on the verification form.",
        MISSING_EBT_CARD_NUMBER: "The EBT card uploaded is missing the card number.",
        MISMATCH_EBT_CARD_NUMBER:
          "The EBT card uploaded has a different number than what was entered into the verification form.",
        INSUFFICIENT_EBT_CARD_NUMBER:
          "The EBT card uploaded is insufficient for establishing eligibility for this offer.",
        EBT_CARD_NUMBER_OTHER:
          "The EBT card submitted is not able to establish eligibility for this offer.",
      },
      uploadInfo: {
        affiliation: "Your current affiliation with that organization",
        fullName: "Your full name",
        organization: "Your organization",
        student: {
          enrollmentDate:
            "Date proving enrollment for the current term (or within the last 3 months)",
          school: "Your school",
        },
        teacher: {
          currentSchoolYear: "A date in the current school year",
          school: "Your school",
        },
        military: {
          currentAffiliation: "Your current affiliation with the armed services",
          dischargeDate: "Your discharge date (if applicable)",
          serviceBranch: "Your branch of service",
          uploadInstructionsDependent:
            "Please upload a document that clearly shows your first name, last name, valid dates, and status",
        },
        senior: {
          birthDate: "Your date of birth",
        },
        age: {
          birthDate: "Your date of birth",
        },
        firstResponder: {
          currentAffiliation: "Your current affiliation with that organization",
          organization: "Your organization",
          license: "Your status or license as a First Responder",
        },
        medical: {
          status: "Your medical professional status",
          validDate: "A currently valid date",
        },
        employment: {
          company: "Your company",
          currentAffiliation: "Your current affiliation with that company",
        },
        identity: {
          validDate: "A currently valid date",
        },
        licensedProfessional: {
          licenseStatus: "Your professional license and/or certification status",
          validDate: "A currently valid date",
        },
        recentMover: {
          address: "Your new address",
          validDate: "A valid date for your recent move",
        },
        lowIncome: {
          fullName: "Your full name",
          validDate:
            "A valid date of issue (within the last 12 months) or date of expiry (must be valid)",
          ebtNumber: "EBT card number",
        },
      },
      acceptableUploads: {
        student: {
          idCard: "School ID card with expiration date",
          classSchedule: "Class schedule",
          tuitionReceipt: "Tuition receipt",
        },
        teacher: {
          idCard: "Teacher ID card with valid date",
          payStub: "Pay stub from within last 60 days",
        },
        member: {
          idCard: "Membership Document",
          payStub: "Pay stub from within last 90 days",
        },
        senior: {
          birthCertificate: "Your birth certificate",
          driversLicense: "Your drivers license",
          passport: "Your passport",
          stateId: "Your state-issued ID",
        },
        age: {
          birthCertificate: "Your birth certificate",
          driversLicense: "Your drivers license",
          passport: "Your passport",
          stateId: "Your state-issued ID",
        },
        military: {
          activeDuty:
            "Any document that proves you are currently serving as active duty in the armed forces for 30 days or more",
          veteran:
            "Any document that proves you met the qualifications of military service and were honorably discharged",
          reservistA:
            "Any document that shows you are currently serving in a reserve component of the armed forces",
          retiree: "Any document that proves you are a retiree from the armed forces",
          dependent: "Any document that proves you are a Registered Military Dependent",
          goldStarFamily: "Any document that proves you are a Registered Gold Star Family Member",
        },
        medical: {
          licenseCertificate: "Certificate of license",
          idCard: "Nursing ID card with expiration date",
          photoPayStub: "Photo ID and recent Pay Stub",
        },
        employment: {
          employeeIdCard: "Employee ID Card",
          payStub: "Pay stub from within last 60 days",
          officialLetter: "An official letter",
        },
        firstResponder: {
          idCard: "ID Card",
          payStub: "Pay stub from within last 60 days",
          letter: "An official letter",
        },
        licensedProfessional: {
          license: "Certificate of license",
        },
        recentMover: {
          changeOfAddress: "U.S. Postal Service change of address confirmation letter",
          mortgageDeed: "Mortgage deed",
          rentalAgreement: "Lease or rental agreement",
        },
        lowIncome: {
          programCard:
            "Back and front of a card showing you qualify for the selected government assistance program (for example, an EBT card for a SNAP Program).",
          programApprovalLetter:
            "Approval letter of the selected government assistance program issued by a government agency (for example, a Supplemental Security Income (SSI) eligibility letter).",
          programApprovalScreenshot:
            "A screenshot of the online portal of the selected government assistance program showing you qualify for it (Screenshot of your Medicare application status on your online account).",
          programBankStatement: "A bank statement showing recent payment of the selected benefit.",
        },
      },
    },
    pending: {
      titleCountdown: "Processing",
      titleReview: "Reviewing",
      subtitle:
        "SheerID is reviewing your documents, to confirm your status. This process may take up to {maxReviewTime}.",
      subtitleAlt: "SheerID is reviewing your documents, to confirm your status.",
      docDiffLang: "We identified your document is in a different language.",
      turnaroundTime:
        "Turnaround time for your language is typically {estReviewTime}, and in rare times of high volume may take up to {maxReviewTime}.",
      subtitle2: "Wait here or let us notify you of the outcome.",
      subtitle3: "You will be contacted via email.",
      subtitleCountdown: "Hang tight while we upload and scan your documents.",
      idCheckLoop: {
        title: "We're reviewing your documents",
        subtitle: "Hold tight, turnaround time is typically a few minutes or less.",
        subtitle2: "We'll send you an email when we're finished reviewing.",
      },
    },
    personalInfo: {
      verifyingTitle: "Verifying your status",
      verifyingSubtitle: "This should only take a minute",
    },
    success: {
      copyCouponCode: "Copy Coupon code",
      emailNotification:
        "We have emailed you a copy so you can use your new code now or later. Look for the email from verify@sheerid.com.",
      verificationOnly:
        "SheerID handles verification only. Now that you've been approved, please direct all questions about the promotion terms to {companyName} customer service.",
    },
    consolation: {
      title: "Claim your offer",
      subtitle:
        "Unfortunately we could not verify your status, but we value your interest and are offering this promo code as a thank you. It is unique to you and can only be used once.",
      verificationOnly: "Please direct any and all questions about this offer to {companyName}.",
    },
    sso: {
      cancel: "Upload documents",
      title: "Verify using your school credentials",
      subtitle: "Please sign in to your school's web portal. It will open in a new tab.",
      subtitle2: "Unable to sign in?",
      login: "Sign in to my school",
      preheader: "Quickest Method",
      alternative: "You can also verify by uploading proof of eligibility",
    },
    collectSocialSecurityNumber: {
      title: "Enter your social security number",
      useSsn: "Verify using your social security number",
      uploadDoc: "Upload a document",
      selectOption: "Select an option",
      uploadInstead: "Upload a document instead",
    },
    smsLoop: {
      titleWithNumber:
        "We've sent an SMS message to the mobile number ending in {number}. Enter your code here.",
      titleWithoutNumber: "We've sent an SMS to your mobile number. Please enter the code here.",
      verificationCode: "Verification Code",
      errors: {
        codeVerification: "Verification code could not be validated.",
        codeExpired: "Verification code could not be validated.",
        codeResendLimit: "Maximum number of re-tries has been reached.",
        resend: "Error sending the SMS message",
      },
      successResend: "SMS message re-sent successfully",
      incorrectNumber: {
        incorrectNumber1: "Incorrect number? Click",
        incorrectNumber2: "here",
        incorrectNumber3: "to return to {companyName}",
      },
      resendButton: "Re-send",
      submitButton: "Submit",
    },
    emailLoop: {
      title: "Check your email",
      subtitleWithEmail:
        "An email has been sent to {email} with a personalized link to complete the verification process.",
      subtitleWithoutEmail:
        "An email has been sent to your email account with a personalized link to complete the verification process.",
      subtitle2: "Please check for an email from us (verify@sheerid.com) for all the details.",
      successResend: "Email re-sent successfully.",
      resendButton: "Re-send",
      errors: {
        resend: "Error sending email",
        invalidEmail:
          "This email address is not valid for instant verification. Please use an email address provided by your employer or upload documentation below.",
      },
      skipEmail: "Unable to retrieve this email? Click",
      skipEmailCTA: "here",
      skipEmail2: "to proceed.",
      collectOrganizationEmail: {
        title: "Verify using your work email address",
        subtitle:
          "Enter the email address provided by your employer and we'll send you a verifcation link",
        email: {
          label: "Work email address",
          send: "Send Email",
        },
        alternative: "Unable to access a work email address?",
      },
    },
    idCheckLoop: {
      title: "Let's verify your identity",
      subtitle: "You'll need access to a mobile phone with a camera or computer with a webcam.",
      continue: "Continue",
      terms:
        'By clicking "Continue", you agree you have read, understand, and accept {scanPolicy}, {privacyPolicyLink}, and {termsOfService}.',
      disclaimer: "SheerID uses a third party, Onfido, to process our identity check.",
      steps: {
        step1: "Step 1",
        step2: "Step 2",
        step3: "Step 3",
        idCheck: {
          title: "Take a photo of your ID",
          subtitle: "You'll need a valid Driver license, State ID, passport, or residence permit.",
        },
        selfie: {
          title: "Take a selfie",
          subtitle: "We'll compare your selfie to your ID photo.",
        },
        docReview: {
          title: "Upload supporting documents",
          subtitle: "We'll compare your documents with your ID photo.",
        },
      },
    },
    override: {
      title: "Unable to Verify Status",
      subtitle: "If applicable, please enter a verification override code below.",
      subtitle2: "This will enable the customer to redeem the offer",
      verificationOverrideCodeLabel: "Verification Override Code",
      submitOverrideCodeButtonLabel: "Submit Override Code",
      errors: {
        invalidCode: "Invalid Override Code.",
        codeOverrideLimit: "Override code limit exceeded.",
      },
    },
    error: {
      errorId: {
        unauthorizedAccountStatus: {
          title: "Offer Is Paused!",
          buttonText: "Contact Support",
        },
        noRemainingRewardCodes: {
          title: "Offer Sold Out!",
          buttonText: "See More Offers",
        },
        verificationLimitExceeded: {
          title: "Verification Limit Exceeded",
        },
        expiredProgram: {
          title: "Offer Sold Out!",
          buttonText: "See More Offers",
        },
        inactiveProgram: {
          title: "Offer Sold Out!",
          buttonText: "See More Offers",
        },
        missingRequiredMetadata: {
          title: "Please log in.",
        },
      },
    },
    collectMedicalPersonalInfo: {
      // The fallback for each of these is temporarily the same, but will be updated once we have full translations
      registrationNumberLabel: "Registration Number",
      registrationNumberExplanation: "Your Professional Membership Registration Number",
      numeroColegiadoLabel: "Registration Number",
      numeroColegiadoExplanation: "Your Professional Membership Registration Number",
      autorisationsIdLabel: "Registration Number",
      autorisationsIdExplanation: "Your Professional Membership Registration Number",
      gbrNummerLabel: "Registration Number",
      gbrNummerExplanation: "Your Professional Membership Registration Number",
      globalLocationNumberLabel: "Global Location Number",
      globalLocationNumberExplanation: "Your Professional Membership Registration Number",
    },
  },
  ssn: "Social Security Number",
  ssnPlaceholder: "000-00-0000",
  getHelp: "Get help",
  // The preferred default:
  footerText:
    "By submitting the personal information above, I acknowledge that my personal information is being collected under the <privacyPolicy>privacy policy</privacyPolicy> of the business from which I am seeking a discount, and I understand that my personal information will be shared with SheerID as a processor/third-party service provider in order for SheerID to confirm my eligibility for a special offer. <aboutSheerID>More about SheerID</aboutSheerID>.",
  // region: Keep these for backward compatibility
  infoShared1:
    "By clicking, I consent to processing my information for the purpose of verifying my eligibility as described within the {privacyPolicyLink}",
  infoShared2: ". I understand that my information will be shared with {companyName}.",
  studentInfoShared:
    "Information entered here will be used for verification purposes and shared with {companyName}.",
  firstResponderInfoShared: "Information entered here will be used for verification purposes.",
  militaryInfoShared: "Information entered here will be used for verification purposes.",
  // endregion: Keep these for backward compatibility
  militaryFamilyCardNote:
    "Note: this offer is only available to military family members with a Dependent ID card.",
};
