/**
 * TODO - preamble
 */
import React, { Suspense, useEffect } from "react";

import { VerificationStep, VerificationService } from "../lib/types/types";
import { VerificationSteps, VerificationStepsEnum } from "../lib/types/runtimeTypes";
import { logger } from "../lib/utils/logger/logger";
import { getOverridenComponent } from "../lib/componentOverride/componentOverride";

import { StepStudentPersonalInfoComponent } from "./StepStudentPersonalInfo/StepStudentPersonalInfoComponent";
import { StepSeniorPersonalInfoComponent } from "./StepSeniorPersonalInfo/StepSeniorPersonalInfoComponent";
import { StepAgePersonalInfoComponent } from "./StepAgePersonalInfo/StepAgePersonalInfoComponent";
import { StepTeacherPersonalInfoComponent } from "./StepTeacherPersonalInfo/StepTeacherPersonalInfoComponent";
import { StepMemberPersonalInfoComponent } from "./StepMemberPersonalInfo/StepMemberPersonalInfoComponent";
import { StepActiveMilitaryPersonalInfoComponent } from "./StepMilitaryPersonalInfo/StepActiveMilitaryPersonalInfoComponent";
import { StepInactiveMilitaryPersonalInfoComponent } from "./StepMilitaryPersonalInfo/StepInactiveMilitaryPersonalInfoComponent";
import { StepFirstResponderPersonalInfoComponent } from "./StepFirstResponderPersonalInfo/StepFirstResponderPersonalInfoComponent";
import { StepMedicalProfessionalPersonalInfoComponent } from "./StepMedicalProfessionalPersonalInfo/StepMedicalProfessionalPersonalInfoComponent";
import { StepEmploymentPersonalInfoComponent } from "./StepEmploymentPersonalInfo/StepEmploymentPersonalInfoComponent";
import { StepDriverLicensePersonalInfoComponent } from "./StepIdentityPersonalInfo/StepDriverLicensePersonalInfoComponent";
import { StepGeneralIdentityPersonalInfoComponent } from "./StepIdentityPersonalInfo/StepGeneralIdentityPersonalInfoComponent";
import { StepHybridIdentityPersonalInfoComponent } from "./StepIdentityPersonalInfo/StepHybridIdentityPersonalInfoComponent";
import { StepLicensedProfessionalPersonalInfoComponent } from "./StepLicensedProfessionalPersonalInfo/StepLicensedProfessionalPersonalInfoComponent";
import { StepMoverPersonalInfoComponent } from "./StepMoverPersonalInfo/StepMoverPersonalInfoComponent";
import { StepLowIncomePersonalInfoComponent } from "./StepLowIncomePersonalInfo/StepLowIncomePersonalInfoComponent";
import { StepCollectMilitaryStatusComponent } from "./StepMilitaryPersonalInfo/StepCollectMilitaryStatusComponent";
import { StepSocialSecurityNumber } from "./StepSocialSecurityNumber/StepSocialSecurityNumber";
import { StepErrorComponent } from "./StepError/StepErrorComponent";
import { StepSSOComponent } from "./StepSSO/StepSSOComponent";
import { LoadingScreenComponent as LoadingScreen } from "./LoadingScreen/LoadingScreenComponent";
import StepSuccessComponent from "./StepSuccess/index";
import StepPendingComponent from "./StepPending/index";
import StepSMSLoopComponent from "./StepSMSLoop/index";
import StepEmailLoopComponent from "./StepEmailLoop/index";
import StepDocUploadComponent from "./StepDocUpload/index";
import StepConsolationComponent from "./StepConsolation/index";
import StepOverrideComponent from "./StepOverrideComponent/index";
import { StepIDCheckLoopComponent } from "./StepIDCheckLoop/StepIDCheckLoopComponent";
import { PerformanceNames } from "../lib/GoogleAnalytics/ga";

const defaultComponents = {
  StepStudentPersonalInfoComponent,
  StepSeniorPersonalInfoComponent,
  StepAgePersonalInfoComponent,
  StepTeacherPersonalInfoComponent,
  StepMemberPersonalInfoComponent,
  StepCollectMilitaryStatusComponent,
  StepActiveMilitaryPersonalInfoComponent,
  StepInactiveMilitaryPersonalInfoComponent,
  StepFirstResponderPersonalInfoComponent,
  StepMedicalProfessionalPersonalInfoComponent,
  StepEmploymentPersonalInfoComponent,
  StepDriverLicensePersonalInfoComponent,
  StepGeneralIdentityPersonalInfoComponent,
  StepHybridIdentityPersonalInfoComponent,
  StepLicensedProfessionalPersonalInfoComponent,
  StepMoverPersonalInfoComponent,
  StepLowIncomePersonalInfoComponent,
  StepSocialSecurityNumber,
  StepSuccessComponent,
  StepConsolationComponent,
  StepOverrideComponent,
  StepDocUploadComponent,
  StepPendingComponent,
  StepSSOComponent,
  StepErrorComponent,
  StepSMSLoopComponent,
  StepEmailLoopComponent,
  StepIDCheckLoopComponent,
};

const getComponent = (componentName) => {
  logger.info("getComponent", componentName);
  const overriddenComponent = getOverridenComponent(componentName);
  if (overriddenComponent) {
    return overriddenComponent;
  }

  return defaultComponents[componentName];
};

const getLoadingScreen = () => <LoadingScreen />;
interface Props {
  stepToLoad: VerificationStep;
  verificationService: VerificationService;
}

const LoadStepComponent = ({ stepToLoad, verificationService }: Props) => {
  logger.info(`LoadStepComponent: rendering step: ${stepToLoad}`);
  let Component;
  let isSecondaryStep = false;

  useEffect(() => {
    // see ObservePerformanceMetrics in ga.ts - we'll use LCP if we have it as
    // it's a better metric, but this gives us something in all browsers.
    try {
      performance.mark(PerformanceNames.INITIAL_STEP_LOAD);
    } catch {
      // jsdom doesn't have `performance` as such tests fall over.
    }
  });

  switch (stepToLoad) {
    case VerificationStepsEnum.collectStudentPersonalInfo:
      Component = getComponent("StepStudentPersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectSeniorPersonalInfo:
      Component = getComponent("StepSeniorPersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectAgePersonalInfo:
      Component = getComponent("StepAgePersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectTeacherPersonalInfo:
      Component = getComponent("StepTeacherPersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectMemberPersonalInfo:
      Component = getComponent("StepMemberPersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectMilitaryStatus:
      Component = getComponent("StepCollectMilitaryStatusComponent");
      break;
    case VerificationStepsEnum.collectActiveMilitaryPersonalInfo:
      Component = getComponent("StepActiveMilitaryPersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectInactiveMilitaryPersonalInfo:
      Component = getComponent("StepInactiveMilitaryPersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectFirstResponderPersonalInfo:
      Component = getComponent("StepFirstResponderPersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectMedicalProfessionalPersonalInfo:
      Component = getComponent("StepMedicalProfessionalPersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectEmployeePersonalInfo:
      Component = getComponent("StepEmploymentPersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectDriverLicensePersonalInfo:
      Component = getComponent("StepDriverLicensePersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectGeneralIdentityPersonalInfo:
      Component = getComponent("StepGeneralIdentityPersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectHybridIdentityPersonalInfo:
      Component = getComponent("StepHybridIdentityPersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectLicensedProfessionalPersonalInfo:
      Component = getComponent("StepLicensedProfessionalPersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectMoverPersonalInfo:
      Component = getComponent("StepMoverPersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectLowIncomePersonalInfo:
      Component = getComponent("StepLowIncomePersonalInfoComponent");
      break;
    case VerificationStepsEnum.collectSocialSecurityNumber:
      Component = getComponent("StepSocialSecurityNumber");
      break;
    case VerificationStepsEnum.success:
      Component = getComponent("StepSuccessComponent");
      isSecondaryStep = true;
      break;
    case VerificationStepsEnum.consolation:
      Component = getComponent("StepConsolationComponent");
      isSecondaryStep = true;
      break;
    case VerificationStepsEnum.override:
      Component = getComponent("StepOverrideComponent");
      isSecondaryStep = true;
      break;
    case VerificationStepsEnum.docUpload:
      Component = getComponent("StepDocUploadComponent");
      isSecondaryStep = true;
      break;
    case VerificationStepsEnum.pending:
      Component = getComponent("StepPendingComponent");
      isSecondaryStep = true;
      break;
    case VerificationStepsEnum.sso:
      Component = getComponent("StepSSOComponent");
      break;
    case VerificationStepsEnum.smsLoop:
      Component = getComponent("StepSMSLoopComponent");
      isSecondaryStep = true;
      break;
    case VerificationStepsEnum.emailLoop:
      Component = getComponent("StepEmailLoopComponent");
      isSecondaryStep = true;
      break;
    case VerificationStepsEnum.idCheckLoop:
      Component = getComponent("StepIDCheckLoopComponent");
      isSecondaryStep = true;
      break;
    case VerificationStepsEnum.missingRequiredMetadata:
      Component = getComponent("StepErrorComponent");
      return (
        <Component verificationService={verificationService} errorId="missingRequiredMetadata" />
      );
    // If explicitly trying to render the error component, such as when testing/styling
    case VerificationStepsEnum.error:
      Component = getComponent("StepErrorComponent");
      break;
    default:
      const msg = `Error: Unknown step to render in LoadStepComponent.
                Unable to render step name "${stepToLoad}". Possible steps are [${VerificationSteps.join(
        ", ",
      )}]`;
      logger.error(msg, "Unknown step in LoadStepComponent");
      return <StepErrorComponent verificationService={verificationService} errorId="invalidStep" />;
  }

  return !isSecondaryStep ? (
    <Component verificationService={verificationService} />
  ) : (
    <Suspense fallback={getLoadingScreen()}>
      <Component verificationService={verificationService} />
    </Suspense>
  );
};

export { LoadStepComponent };
