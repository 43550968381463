/**
 * TODO - preamble
 */
import "./main.scss";

import "./polyfills/polyfills";

import { SheerIdJsApi } from "./lib/types/types";

import { VerificationFormEs5 } from "./es5Bindings/VerificationFormEs5";
import { BirthdateField } from "./es5Bindings/BirthdateField";
import { StudentTypeaheadField } from "./es5Bindings/StudentTypeaheadField";

// Import as much as possible from es6, to recycle that interface and ensure it stays functional
import {
  addHook,
  allMockedResponses,
  conversion,
  setViewModel,
  resetViewModel,
  refreshStore,
  setOptions,
  resetOptions,
  setMetadata,
  getMetadata,
  resetMetadata,
  overrideComponent,
  resetOverriddenComponents,
  loadInModal,
  loadInlineIframe,
  postVerificationSizeUpdates,
  getSafe,
  getMessages,
  collectDeviceProfile,
  resetStore,
} from "./es6";
import { getRequestedVersion, getSheerIdScriptBasePath } from "./lib/ServerApi/ApiUrls";

// Modify window interface to allow the SheerID object.
declare global {
  interface Window {
    sheerId: SheerIdJsApi;
    NREUM: any; // New Relic
    gtag: any; // Google Analytics (GA4)
    /** @deprecated Use window.gtag instead */
    ga: any; // Google Analytics (legacy)
    IPQ: any; // IPQS
    Startup: any; // IPQS
    sheerIdReqV: string; // src string of the jslib <script> tag, e.g. https://cdn.jsdelivr.net/npm/@sheerid/jslib-nightly@alpha/sheerid.js
    sheerIdPubV: string; // published version. The full version name baked into the jslib artifact, e.g. `1.59.0` even if e.g. @latest was the src address
    sheerIdInstallType: string; // for custom programs to tell the jslib which install type they are using. Use "npm_package" for offers.sheerid.com projects
    sheerIdProjectSha: string; // for custom programs to tell the jslib what the git sha of the project is. Do not use the jslib git sha.
    sheerIdCurrentScript: HTMLOrSVGScriptElement | null;
    sheerIdEs5: string;
    __webpack_public_path__: string; // eslint-disable-line camelcase
  }
}

// If you just use the outputted bundle, and don't include the es6 source code in your project/build,
// this SheerID object is the API you'd interact with.
// See /examples/main.html
// Implements the SheerIdJsApi interface
// window.sheerid interface:
const thisJslib: SheerIdJsApi = {
  getMessages,
  setViewModel,
  resetViewModel,
  setOptions,
  resetOptions,
  refreshStore,
  setMetadata,
  getMetadata,
  resetMetadata,
  StudentTypeaheadField,
  BirthdateField,
  conversion,
  overrideComponent,
  resetOverriddenComponents,
  loadInModal,
  loadInlineIframe,
  postVerificationSizeUpdates,
  addHook,
  allMockedResponses,
  hooks: undefined,
  VerificationForm: VerificationFormEs5,
  collectDeviceProfile,
  resetStore,
};

window.sheerId = {
  ...(window.sheerId || {}),
  ...thisJslib,
  hooks: getSafe(() => window.sheerId.hooks, []),
};

// TODO - remove. Use es6.tsx instead
export { setOptions, resetOptions } from "./options/options";
export { VerificationForm } from "./components/VerificationForm/VerificationFormComponent";
export {
  overrideComponent,
  resetOverriddenComponents,
} from "./lib/componentOverride/componentOverride";
// END TODO - remove. Use es6.tsx instead

// The following lines provide support for loading other scripts of chunks from the same URL as the main sheerid.js file.
// More information about this variable https://github.com/webpack/webpack/issues/443
declare let __webpack_public_path__: string; // eslint-disable-line

(() => {
  __webpack_public_path__ = getSheerIdScriptBasePath(); // eslint-disable-line

  // Set this up for the logger
  window.sheerIdReqV = getRequestedVersion();
})();
