/**
 * TODO - preamble
 */
import {
  getDocTypeForCountriesUrl,
  getFieldsToCollectUrl,
  getInstallPageUrl,
  getNewEmailCodeResendUrl,
  getNewSmsCodeResendUrl,
  getNewVerificationRequestUrl,
  getProgramThemeUrl,
} from "./ApiUrls";
import { getOverriddenMock, getRouteOverride } from "./TestingRouteOverrides";
import { DeleteJson, GetJson, GetResponse, PostFiles, PostJson } from "./Network";
import { fetchExistingVerificationRequest } from "./VerificationApiClient/fetchExistingVerificationRequest";

import { logger } from "../utils/logger/logger";

import {
  ActiveMilitaryPersonalInfoRequest,
  ActiveMilitaryPersonalInfoViewModel,
  AgePersonalInfoRequest,
  AgePersonalInfoViewModel,
  ApplicableDocTypesResponse,
  CollectFieldsResponse,
  DatabaseId,
  DocUploadRequest,
  DocUploadResponse,
  DocUploadViewModel,
  DriverLicensePersonalInfoRequest,
  DriverLicensePersonalInfoViewModel,
  EmailLoopCollectOrganizationEmailViewModel,
  EmailLoopCollectOrganizationRequest,
  EmailLoopResponse,
  EmailLoopVerificationRequest,
  EmailLoopViewModel,
  EmploymentPersonalInfoRequest,
  EmploymentPersonalInfoViewModel,
  ErrorResponse,
  FirstResponderPersonalInfoRequest,
  FirstResponderPersonalInfoViewModel,
  GeneralIdentityPersonalInfoRequest,
  GeneralIdentityPersonalInfoViewModel,
  HybridIdentityPersonalInfoRequest,
  HybridIdentityPersonalInfoViewModel,
  IDCheckLoopVerificationRequest,
  InactiveMilitaryPersonalInfoRequest,
  InactiveMilitaryPersonalInfoViewModel,
  LicensedProfessionalPersonalInfoRequest,
  LicensedProfessionalPersonalInfoViewModel,
  Locale,
  LowIncomePersonalInfoRequest,
  LowIncomePersonalInfoViewModel,
  MedicalProfessionalPersonalInfoRequest,
  MedicalProfessionalPersonalInfoViewModel,
  MemberPersonalInfoRequest,
  MemberPersonalInfoViewModel,
  MilitaryStatusRequest,
  MockStep,
  MoverPersonalInfoRequest,
  MoverPersonalInfoViewModel,
  NetworkErrorId,
  NewVerificationRequest,
  OverrideVerificationRequest,
  OverrideViewModel,
  PersonalInfoResponse,
  ProgramTheme,
  SeniorPersonalInfoRequest,
  SeniorPersonalInfoViewModel,
  SMSLoopVerificationRequest,
  SMSLoopViewModel,
  SocialSecurityRequest,
  SocialSecurityResponse,
  SocialSecurityViewModel,
  SSOResponse,
  StudentPersonalInfoRequest,
  StudentPersonalInfoViewModel,
  TeacherPersonalInfoRequest,
  TeacherPersonalInfoViewModel,
  VerificationResponse,
  VerificationStep,
  ViewModel,
} from "../types/types";

import { VerificationStepsEnum } from "../types/runtimeTypes";
import {
  assertValidLocale,
  assertValidProgramId,
  assertValidVerificationStepName,
} from "../types/assertions";
import { getVerificationIdFromQueryString } from "../utils/routing/Url";
import { getOptions } from "../../options/options";
import { GetEmptyTheme } from "../types/empties";
import { resolveTrackingId } from "../conversion/conversion";
import { getLocaleSafely } from "../intl/intl";
import { DimensionName, setDimension } from "../GoogleAnalytics/ga";

/**
 * Initiate a new verification attempt with the SheerID REST service.
 *
 * @param programId Your programId from my.sheerid.com
 * @param trackingId Conversion trackingId to associate with this verification attempt.
 */
async function fetchNewVerificationRequest(
  programId: DatabaseId,
  trackingId: string = undefined,
  forceNewVerificationRequest = false,
  headers = {},
): Promise<VerificationResponse> {
  const resolvedTrackingId = resolveTrackingId(trackingId);

  try {
    let response: VerificationResponse;

    assertValidProgramId(programId);

    const windowQueryString = window.location.search;
    let verificationId;

    if (!forceNewVerificationRequest) {
      verificationId =
        getOptions().verificationId || getVerificationIdFromQueryString(windowQueryString);
    }

    if (verificationId) {
      logger.info("fetchNewVerificationRequest: Calling for existing verification request");
      response = await fetchExistingVerificationRequest(verificationId);
    }

    if (
      !verificationId ||
      (response.currentStep === VerificationStepsEnum.error &&
        response.errorIds.includes("noVerification"))
    ) {
      const url: string = getNewVerificationRequestUrl();
      const requestBody: NewVerificationRequest = {
        programId,
      };

      if (resolvedTrackingId) {
        logger.info(`Adding trackingId ${resolvedTrackingId}`);
        requestBody.trackingId = resolvedTrackingId;
      }

      const installPageUrl = getInstallPageUrl();
      if (installPageUrl) {
        logger.info(`Adding installPageUrl ${installPageUrl}`);
        requestBody.installPageUrl = installPageUrl;
      }

      response = (await PostJson(url, requestBody, headers)) as VerificationResponse;

      // This is a new verification response it will confidently declare it's
      // locale is `en-US` In most cases we want the verification response's
      // locale to win to respect the locale a users is verifying in. For new
      // verifications we want to use the best locale NOT including the
      // verification response. So we set the response's locale to that so we
      // can treat it as correct from here out.
      response.locale = getLocaleSafely();
    }

    const overriddenStep: MockStep = getRouteOverride(response);
    const mockResponse: VerificationResponse = await getOverriddenMock(overriddenStep, response);

    if (mockResponse) {
      // Reset the mocked locale to the best non-response locale when loading a
      // mock. This ensures that we load the correct theme/messages.
      mockResponse.locale = getLocaleSafely();
      return mockResponse;
    }

    return response;
  } catch (e) {
    logger.error(e, "fetchNewVerificationRequest");
    throw e;
  }
}

async function fetchProgramTheme(programId: DatabaseId, locale?: Locale): Promise<ProgramTheme> {
  if (!getOptions().doFetchTheme) {
    return GetEmptyTheme();
  }
  logger.info("fetchProgramTheme: Calling back-end for program theme");

  try {
    assertValidProgramId(programId);

    if (locale) assertValidLocale(locale);
    const cleanLocale = locale || getLocaleSafely();

    return (await GetJson(getProgramThemeUrl(programId, cleanLocale))) as ProgramTheme;
  } catch (e) {
    logger.error(e, "fetchProgramTheme");
    throw e;
  }
}

async function getResendNewSmsCode(verificationId: DatabaseId): Promise<any> {
  logger.info("Resending new sms code");

  try {
    const url = getNewSmsCodeResendUrl(verificationId);
    const response = await GetResponse(url);
    return response;
  } catch (e) {
    logger.error(e, "getResendNewSmsCode");
  }
}

async function getResendNewEmailCode(verificationId: DatabaseId): Promise<any> {
  logger.info("Resending new email code");

  try {
    const url = getNewEmailCodeResendUrl(verificationId);
    const response = await GetResponse(url);
    return response;
  } catch (e) {
    logger.error(e, "getResendNewEmailCode");
  }
}

export function getMockVerificationRequestErrorResponse(
  errorId: NetworkErrorId,
): VerificationResponse {
  return {
    verificationId: null,
    currentStep: "error",
    errorIds: [errorId],
    segment: null,
    subSegment: null,
    locale: "en-US",
  };
}

// StudentPersonalInfoViewModel contains multiple keys we don't want so we can't return the full viewModel as the request body
const studentViewModelToRequest = (
  viewModel: StudentPersonalInfoViewModel,
): StudentPersonalInfoRequest => ({
  firstName: viewModel.firstName,
  lastName: viewModel.lastName,
  birthDate: viewModel.birthDate,
  email: viewModel.email,
  phoneNumber: viewModel.phoneNumber,
  organization: viewModel.organization,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
  locale: viewModel.localeChoice.value,
  metadata: viewModel.metadata,
});

// TeacherPersonalInfoViewModel contains multiple keys we don't want so we can't return the full viewModel as the request body
const teacherViewModelToRequest = (
  viewModel: TeacherPersonalInfoViewModel,
): TeacherPersonalInfoRequest => ({
  firstName: viewModel.firstName,
  lastName: viewModel.lastName,
  birthDate: viewModel.birthDate,
  postalCode: viewModel.postalCode,
  email: viewModel.email,
  phoneNumber: viewModel.phoneNumber,
  organization: viewModel.organization,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
  locale: viewModel.localeChoice.value,
  metadata: viewModel.metadata,
});

export const memberViewModelToRequest = (
  viewModel: MemberPersonalInfoViewModel,
): MemberPersonalInfoRequest => ({
  firstName: viewModel.firstName,
  lastName: viewModel.lastName,
  birthDate: viewModel.birthDate,
  memberId: viewModel.memberId,
  email: viewModel.email,
  phoneNumber: viewModel.phoneNumber,
  organization: viewModel.organization,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
  locale: viewModel.localeChoice.value,
  metadata: viewModel.metadata,
  address1: viewModel.address1,
  city: viewModel.city,
  state: viewModel.state,
  postalCode: viewModel.postalCode,
  country: viewModel.countryChoice ? viewModel.countryChoice.value : undefined,
});

const seniorViewModelToRequest = (
  viewModel: SeniorPersonalInfoViewModel,
): SeniorPersonalInfoRequest => ({
  firstName: viewModel.firstName,
  lastName: viewModel.lastName,
  birthDate: viewModel.birthDate,
  email: viewModel.email,
  postalCode: viewModel.postalCode,
  phoneNumber: viewModel.phoneNumber,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
  locale: viewModel.localeChoice.value,
  metadata: viewModel.metadata,
});

const ageViewModelToRequest = (viewModel: AgePersonalInfoViewModel): AgePersonalInfoRequest => ({
  firstName: viewModel.firstName,
  lastName: viewModel.lastName,
  birthDate: viewModel.birthDate,
  email: viewModel.email,
  postalCode: viewModel.postalCode !== "" ? viewModel.postalCode : undefined,
  phoneNumber: viewModel.phoneNumber !== "" ? viewModel.phoneNumber : undefined,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
  locale: viewModel.localeChoice.value,
  country: viewModel.countryChoice ? viewModel.countryChoice.value : undefined,
  city: viewModel.city !== "" ? viewModel.city : undefined,
  address1: viewModel.address1 !== "" ? viewModel.address1 : undefined,
  metadata: viewModel.metadata,
});

// ActiveMilitaryPersonalInfoViewModel contains multiple keys we don't want so we can't return the full viewModel as the request body
const militaryStatusViewModelToRequest = (
  viewModel: ActiveMilitaryPersonalInfoViewModel,
): MilitaryStatusRequest => ({
  status: viewModel.status,
});

// ActiveMilitaryPersonalInfoViewModel contains 'status' which we don't want in the request so we can't return the full viewModel
const activeMilitaryViewModelToRequest = (
  viewModel: ActiveMilitaryPersonalInfoViewModel,
): ActiveMilitaryPersonalInfoRequest => ({
  firstName: viewModel.firstName,
  lastName: viewModel.lastName,
  birthDate: viewModel.birthDate,
  activeDutyStartDate: viewModel.activeDutyStartDate || undefined,
  email: viewModel.email,
  phoneNumber: viewModel.phoneNumber,
  organization: viewModel.organization,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
  locale: viewModel.localeChoice.value,
  country: viewModel.countryChoice ? viewModel.countryChoice.value : undefined,
  metadata: viewModel.metadata,
});

// InactiveMilitaryPersonalInfoViewModel contains 'status' which we don't want in the request so we can't return the full viewModel
const inactiveMilitaryViewModelToRequest = (
  viewModel: InactiveMilitaryPersonalInfoViewModel,
): InactiveMilitaryPersonalInfoRequest => ({
  firstName: viewModel.firstName,
  lastName: viewModel.lastName,
  birthDate: viewModel.birthDate,
  activeDutyStartDate: viewModel.activeDutyStartDate || undefined,
  email: viewModel.email,
  phoneNumber: viewModel.phoneNumber,
  organization: viewModel.organization,
  dischargeDate: viewModel.dischargeDate,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
  locale: viewModel.localeChoice.value,
  country: viewModel.countryChoice ? viewModel.countryChoice.value : undefined,
  metadata: viewModel.metadata,
});

export const firstResponderViewModelToRequest = (
  viewModel: FirstResponderPersonalInfoViewModel,
): FirstResponderPersonalInfoRequest => {
  const request: FirstResponderPersonalInfoRequest = {
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    birthDate: viewModel.birthDate,
    email: viewModel.email,
    status: viewModel.status,
    postalCode: viewModel.postalCode,
    phoneNumber: viewModel.phoneNumber,
    organization: viewModel.organization,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
    locale: viewModel.localeChoice.value,
    country: viewModel.countryChoice ? viewModel.countryChoice.value : undefined,
    metadata: viewModel.metadata,
  };

  const hasStatuses =
    viewModel.statuses && Array.isArray(viewModel.statuses) && viewModel.statuses.length > 0;
  if (hasStatuses) {
    request.statuses = viewModel.statuses;
    delete request.status; // API prefers the statuses field only
  }

  if (!(viewModel.status || hasStatuses)) {
    logger.error("Either status or statuses must be provided on First Responder view model");
  }

  return request;
};

const medicalProfessionalViewModelToRequest = (
  viewModel: MedicalProfessionalPersonalInfoViewModel,
): MedicalProfessionalPersonalInfoRequest => ({
  firstName: viewModel.firstName,
  lastName: viewModel.lastName,
  birthDate: viewModel.birthDate,
  status: viewModel.status,
  email: viewModel.email,
  postalCode: viewModel.postalCode,
  phoneNumber: viewModel.phoneNumber,
  organization: viewModel.organization,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
  locale: viewModel.localeChoice.value,
  metadata: viewModel.metadata,
  country: viewModel.countryChoice ? viewModel.countryChoice.value : undefined,
  memberId: viewModel.memberId,
});

const employmentViewModelToRequest = (
  viewModel: EmploymentPersonalInfoViewModel,
): EmploymentPersonalInfoRequest => ({
  firstName: viewModel.firstName,
  lastName: viewModel.lastName,
  email: viewModel.email,
  address1: viewModel.address1,
  city: viewModel.city,
  state: viewModel.state,
  postalCode: viewModel.postalCode,
  phoneNumber: viewModel.phoneNumber,
  organization: viewModel.organization,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
  locale: viewModel.localeChoice.value,
  metadata: viewModel.metadata,
  country: viewModel.countryChoice ? viewModel.countryChoice.value : undefined,
});

const driverLicenseViewModelToRequest = (
  viewModel: DriverLicensePersonalInfoViewModel,
): DriverLicensePersonalInfoRequest => ({
  firstName: viewModel.firstName,
  lastName: viewModel.lastName,
  email: viewModel.email,
  phoneNumber: viewModel.phoneNumber,
  state: viewModel.state,
  driverLicenseNumber: viewModel.driverLicenseNumber,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
  locale: viewModel.localeChoice.value,
  metadata: viewModel.metadata,
});

const generalIdentityViewModelToRequest = (
  viewModel: GeneralIdentityPersonalInfoViewModel,
): GeneralIdentityPersonalInfoRequest => ({
  firstName: viewModel.firstName,
  lastName: viewModel.lastName,
  email: viewModel.email,
  phoneNumber: viewModel.phoneNumber,
  birthDate: viewModel.birthDate,
  address1: viewModel.address1,
  city: viewModel.city,
  state: viewModel.state,
  postalCode: viewModel.postalCode,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
  locale: viewModel.localeChoice.value,
  metadata: viewModel.metadata,
});

const hybridIdentityViewModelToRequest = (
  viewModel: HybridIdentityPersonalInfoViewModel,
): HybridIdentityPersonalInfoRequest => ({
  firstName: viewModel.firstName,
  lastName: viewModel.lastName,
  email: viewModel.email,
  phoneNumber: viewModel.phoneNumber,
  birthDate: viewModel.birthDate,
  address1: viewModel.address1,
  city: viewModel.city,
  state: viewModel.state,
  postalCode: viewModel.postalCode,
  driverLicenseNumber: viewModel.driverLicenseNumber,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
  locale: viewModel.localeChoice.value,
  metadata: viewModel.metadata,
});

const licensedProfessionalViewModelToRequest = (
  viewModel: LicensedProfessionalPersonalInfoViewModel,
): LicensedProfessionalPersonalInfoRequest => ({
  firstName: viewModel.firstName,
  lastName: viewModel.lastName,
  email: viewModel.email,
  phoneNumber: viewModel.phoneNumber,
  birthDate: viewModel.birthDate,
  postalCode: viewModel.postalCode,
  statuses: viewModel.statuses,
  organization: viewModel.organization,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
  locale: viewModel.localeChoice.value,
  metadata: viewModel.metadata,
});

const moverViewModelToRequest = (
  viewModel: MoverPersonalInfoViewModel,
): MoverPersonalInfoRequest => ({
  firstName: viewModel.firstName,
  lastName: viewModel.lastName,
  email: viewModel.email,
  statuses: viewModel.statuses,
  address1: viewModel.address1,
  postalCode: viewModel.postalCode,
  phoneNumber: viewModel.phoneNumber,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
  locale: viewModel.localeChoice.value,
  metadata: viewModel.metadata,
});

export const lowIncomeViewModelToRequest = (
  viewModel: LowIncomePersonalInfoViewModel,
): LowIncomePersonalInfoRequest => ({
  firstName: viewModel.firstName,
  lastName: viewModel.lastName,
  email: viewModel.email,
  phoneNumber: viewModel.phoneNumber,
  birthDate: viewModel.birthDate,
  address1: viewModel.address1,
  city: viewModel.city,
  state: viewModel.state,
  postalCode: viewModel.postalCode,
  statuses: viewModel.statuses,
  organization: viewModel.statuses.includes("OTHER_GOVERNMENT_ASSISTANCE")
    ? viewModel.organization
    : undefined,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
  ebtCardNumber: viewModel.statuses.includes("SNAP_BENEFITS") ? viewModel.ebtCardNumber : undefined,
  locale: viewModel.localeChoice.value,
  metadata: viewModel.metadata,
});

const smsLoopViewModelToRequest = (viewModel: SMSLoopViewModel): SMSLoopVerificationRequest => ({
  smsCode: viewModel.smsCode,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
});

const emailLoopViewModelToRequest = (
  viewModel: EmailLoopViewModel,
): EmailLoopVerificationRequest => ({
  emailToken: viewModel.emailToken,
  deviceFingerprintHash: viewModel.deviceFingerprintHash,
});

const emailLoopCollectOrganizationEmailViewModelToRequest = (
  viewModel: EmailLoopCollectOrganizationEmailViewModel,
): EmailLoopCollectOrganizationRequest => ({
  emailAddress: viewModel.organizationEmail,
});

const idCheckLoopViewModelToRequest = (): IDCheckLoopVerificationRequest => ({});

const overrideViewModelToRequest = (viewModel: OverrideViewModel): OverrideVerificationRequest => ({
  overrideCode: viewModel.overrideCode,
});

const socialSecurityViewModelToRequest = (
  viewModel: SocialSecurityViewModel,
): SocialSecurityRequest => {
  if (
    typeof viewModel.socialSecurityNumber !== "string" ||
    viewModel.socialSecurityNumber.length < 1
  ) {
    throw new Error("Poorly formed social security number, unable to submit");
  }

  const socialSecurityNumber = Number.parseInt(viewModel.socialSecurityNumber, 10); // Server wants an integer
  const requestBody: SocialSecurityRequest = { socialSecurityNumber };
  return requestBody;
};

const stepToRequest = {
  collectStudentPersonalInfo: studentViewModelToRequest,
  collectSeniorPersonalInfo: seniorViewModelToRequest,
  collectAgePersonalInfo: ageViewModelToRequest,
  collectMilitaryStatus: militaryStatusViewModelToRequest,
  collectActiveMilitaryPersonalInfo: activeMilitaryViewModelToRequest,
  collectInactiveMilitaryPersonalInfo: inactiveMilitaryViewModelToRequest,
  collectFirstResponderPersonalInfo: firstResponderViewModelToRequest,
  collectMedicalProfessionalPersonalInfo: medicalProfessionalViewModelToRequest,
  collectMemberPersonalInfo: memberViewModelToRequest,
  collectEmployeePersonalInfo: employmentViewModelToRequest,
  collectTeacherPersonalInfo: teacherViewModelToRequest,
  collectSocialSecurityNumber: socialSecurityViewModelToRequest,
  collectDriverLicensePersonalInfo: driverLicenseViewModelToRequest,
  collectGeneralIdentityPersonalInfo: generalIdentityViewModelToRequest,
  collectHybridIdentityPersonalInfo: hybridIdentityViewModelToRequest,
  collectLicensedProfessionalPersonalInfo: licensedProfessionalViewModelToRequest,
  collectMoverPersonalInfo: moverViewModelToRequest,
  collectLowIncomePersonalInfo: lowIncomeViewModelToRequest,
  smsLoop: smsLoopViewModelToRequest,
  emailLoop: emailLoopViewModelToRequest,
  emailLoopCollectOrganizationEmail: emailLoopCollectOrganizationEmailViewModelToRequest,
  idCheckLoop: idCheckLoopViewModelToRequest,
  override: overrideViewModelToRequest,
  cancelSocialSecurityNumber: null,
  sso: null,
  docUpload: null,
};

async function submitFromVerificationStep(
  step: VerificationStep,
  previousResponse: VerificationResponse,
  viewModel: ViewModel,
): Promise<VerificationResponse | ErrorResponse> {
  let response;
  const { docUpload, cancelSocialSecurityNumber, sso, cancelEmailLoop, cancelDocUpload } =
    VerificationStepsEnum;
  try {
    if (step === docUpload) {
      const requestBody: DocUploadRequest = viewModel as DocUploadViewModel;
      const { file1, file2, file3 } = requestBody;

      response = await PostFiles((previousResponse as DocUploadResponse).submissionUrl, [
        file1,
        file2,
        file3,
      ]);

      return response;
    }
    if (step === cancelSocialSecurityNumber || step === sso) {
      response = await DeleteJson(
        (previousResponse as SSOResponse | SocialSecurityResponse).cancelUrl,
      );
      return response;
    }
    if (step === cancelEmailLoop) {
      response = await DeleteJson((previousResponse as EmailLoopResponse).cancelUrl);
      return response;
    }
    if (step === cancelDocUpload) {
      response = await DeleteJson((previousResponse as DocUploadResponse).submissionUrl);
      return response;
    }
    const requestBody = stepToRequest[step](viewModel);

    /*
      This demographic tracking is added here because the birthDate, when present, is guaranteed to be finalized at this
      point.
      TODO: Revisit managing metric dimensions in this flow as part of https://sheerid.atlassian.net/browse/HC-181
     */
    observeAgeForMetrics(requestBody?.birthDate);

    response = await PostJson(
      (previousResponse as PersonalInfoResponse).submissionUrl,
      requestBody,
    );

    return response;
  } catch (e) {
    logger.error(e, "submitFromVerificationStep");
    // At least show the error step...
    return getUnrecoverableErrorStep();
  }
}

function observeAgeForMetrics(birthDateString: string) {
  if (birthDateString) {
    const currentYear = new Date().getUTCFullYear();
    const birthYear = new Date(Date.parse(birthDateString)).getUTCFullYear();
    if (!Number.isNaN(birthYear)) {
      setDimension(DimensionName.age, (currentYear - birthYear).toString());
    }
  }
}

function getUnrecoverableErrorStep(): ErrorResponse {
  // TODO build an empty error step response with "unknown error" or some other appropriate error id.
  const response: ErrorResponse = {
    verificationId: "",
    currentStep: "error",
    segment: null,
    subSegment: null,
    redirectUrl: null,
    locale: "en-US",
    errorIds: ["unknownError"],
  };
  return response;
}

async function submitStep(
  stepName: VerificationStep,
  previousResponse: VerificationResponse,
  viewModel: ViewModel,
): Promise<VerificationResponse | ErrorResponse> {
  assertValidVerificationStepName(stepName);
  if (VerificationStepsEnum[stepName]) {
    return submitFromVerificationStep(stepName, previousResponse, viewModel);
  }
  return Promise.reject(new Error(`Unknown step ${stepName}`));
}

/**
 * EXPERIMENTAL - Check which fields are required and optional for a given view model
 */
export async function getFieldsToCollect(
  verificationId: DatabaseId,
  step: VerificationStep,
  viewModel: ViewModel,
): Promise<CollectFieldsResponse> {
  if (VerificationStepsEnum[step]) {
    const requestBody = stepToRequest[step](viewModel);
    const response = (await PostJson(
      getFieldsToCollectUrl(verificationId, step),
      requestBody,
    )) as VerificationResponse;
    if (response.currentStep === "error") {
      Promise.reject(new Error(`Failed to determine fields to collect: ${response}`));
    }
    return response as CollectFieldsResponse;
  }
  return Promise.reject(new Error(`Unknown step ${step}`));
}

const getIdCheckDocTypeForCountry = async (
  verificationId: DatabaseId,
  countries: string[],
): Promise<ApplicableDocTypesResponse> =>
  GetJson(
    getDocTypeForCountriesUrl(verificationId, countries),
  ) as Promise<ApplicableDocTypesResponse>;

export const VerificationApiClient = {
  fetchNewVerificationRequest,
  fetchExistingVerificationRequest,
  fetchProgramTheme,
  getResendNewSmsCode,
  getResendNewEmailCode,
  getIdCheckDocTypeForCountry,
  submitStep,
};
