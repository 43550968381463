import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { produce } from "immer";

import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { isFormFilled } from "../../lib/validators/validators";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";
import { setRef } from "../../lib/refs/refs";
import {
  FormSelectChoice,
  LowIncomePersonalInfoResponse,
  LowIncomePersonalInfoViewModel,
  LowIncomeStatus,
  Organization,
  State,
  VerificationService,
} from "../../lib/types/types";
import {
  getAvailableStateChoices,
  handleEmailOnKeyDown,
  orgToOption,
  submitForm,
  updateFieldValidationErrorsByFieldId,
  updateViewModelOrganization,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";
import { HowDoesVerifyingWorkComponent as HowDoesVerifyingWork } from "../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { StatusComponent as LowIncomeStatusComponent } from "../FormFields/Status/StatusComponent";
import { LowIncomeOrganizationComponent as LowIncomeOrganization } from "../FormFields/LowIncomeOrganization/LowIncomeOrganizationComponent";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { PostalCodeComponent } from "../FormFields/PostalCode/PostalCodeComponent";
import { LogoComponent } from "../LogoComponent/LogoComponent";
import { RewardsRemainingComponent } from "../RewardsRemaining/RewardsRemainingComponent";
import { ChangeLocaleV2Component } from "../FormFields/ChangeLocale/ChangeLocaleComponent";
import { CountryComponentWrapper } from "../FormFields/Country/CountryComponentWrapper";
import { EBTCardNumberComponent } from "../FormFields/EBTCardNumber/EBTCardNumberComponent";
import { setViewModel } from "../../lib/VerificationService/ViewModel";
import { AddressComponent } from "../FormFields/Address/AddressComponent";
import { CityComponent } from "../FormFields/City/City";
import { StateSelectComponent } from "../FormFields/State/StateSelectComponent";

interface StepLowIncomePersonalInfoComponentProps {
  verificationService: VerificationService;
  intl: any;
}

type DefaultStatusMessages = {
  [key in LowIncomeStatus]: string;
};

const defaultStatusMessages: DefaultStatusMessages = {
  SNAP_BENEFITS: "Snap Benefits Recipient",
  OTHER_GOVERNMENT_ASSISTANCE: "Other Government Assistance Recipient",
};

const StepLowIncomePersonalInfo = ({
  intl,
  verificationService,
}: StepLowIncomePersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as LowIncomePersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const verificationResponse =
    verificationService.verificationResponse as LowIncomePersonalInfoResponse;
  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);

  const locale = viewModel.localeChoice.value;

  const selectedStatus = viewModel.statuses[0] ? viewModel.statuses[0] : undefined;
  const isSelectedStatusSnapBenefits = selectedStatus === "SNAP_BENEFITS";
  const updateLowIncomeViewModel = (key: keyof LowIncomePersonalInfoViewModel, value: any) => {
    const nextState: LowIncomePersonalInfoViewModel = produce(
      viewModel,
      (draft: LowIncomePersonalInfoViewModel) => {
        (draft[key] as any) = value;
      },
    );
    verificationService.updateViewModel(nextState);
  };

  const getStatusLabel = (status: LowIncomeStatus | undefined): string => {
    if (!status) {
      return "";
    }
    return intl.formatHTMLMessage({ id: status, defaultMessage: defaultStatusMessages[status] });
  };

  const getAvailableLowIncomeStatuses = () => {
    const availableStatusesResponse = verificationResponse.availableStatuses;
    const availableStatuses: FormSelectChoice[] = [];

    if (availableStatusesResponse) {
      for (const status of availableStatusesResponse) {
        availableStatuses.push({
          value: status,
          label: getStatusLabel(status),
        });
      }
    }
    return availableStatuses;
  };

  React.useEffect(() => {
    if (isSelectedStatusSnapBenefits) {
      const updatedViewModel = { fieldsToSkipValidation: ["organization"] };
      setViewModel(updatedViewModel);
    }
  }, [isSelectedStatusSnapBenefits]);
  return (
    <div
      id="sid-step-low-income-personal-info"
      className="sid-low-income-personal-info-container sid-l-container"
    >
      {failedInstantMatch ? (
        <div className="sid-header">
          <div className="sid-low-income-logo-container sid-l-horz-center">
            <LogoComponent verificationService={verificationService} />
          </div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.tryAgain.title"
              defaultMessage="Check Your full name & other fields"
              tagName="h1"
            />
          </div>

          <div className="sid-header__subtitle sid-l-horz-center sid-header__subtitle--error">
            <FormattedHTMLMessage
              id="step.personalInfo.tryAgain.subtitle"
              defaultMessage="Your information must match what is on your government-issued document(s)."
            />
          </div>
        </div>
      ) : (
        <div className="sid-header">
          <div className="sid-low-income-logo-container sid-l-horz-center">
            <LogoComponent verificationService={verificationService} />
          </div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.title"
              defaultMessage="Unlock this Special Offer"
              tagName="h1"
            />
          </div>

          <div className="sid-header__subtitle sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.subtitle"
              defaultMessage="Verify you qualify for an eligible government assistance program."
            />
          </div>
          <div className="sid-header__how-verifying-works sid-l-horz-center">
            <HowDoesVerifyingWork verificationService={verificationService} />
            <RewardsRemainingComponent verificationService={verificationService} />
          </div>
        </div>
      )}

      <ChangeLocaleV2Component verificationService={verificationService} />
      <CountryComponentWrapper verificationService={verificationService} />

      <LowIncomeStatusComponent
        segmentId="low-income"
        value={{
          value: viewModel.statuses ? viewModel.statuses[0] : "",
          label: viewModel.statuses ? getStatusLabel(viewModel.statuses[0]) : "",
        }}
        isErrored={!!fieldValidationErrors.statuses}
        options={getAvailableLowIncomeStatuses()}
        onChange={(status: FormSelectChoice) => {
          const updatedStatuses = status ? [status.value as string] : [];
          updateLowIncomeViewModel("statuses", updatedStatuses);
          updateFieldValidationErrorsByFieldId(
            "statuses",
            status ? (status.value as string) : "",
            verificationService,
          );
        }}
      />
      {selectedStatus === "SNAP_BENEFITS" && (
        <EBTCardNumberComponent
          value={viewModel.ebtCardNumber}
          isErrored={!!fieldValidationErrors.ebtCardNumber}
          onChange={(newValue) => {
            updateLowIncomeViewModel("ebtCardNumber", newValue);
            updateFieldValidationErrorsByFieldId("ebtCardNumber", newValue, verificationService);
          }}
        />
      )}
      {selectedStatus === "OTHER_GOVERNMENT_ASSISTANCE" && (
        <LowIncomeOrganization
          isErrored={!!fieldValidationErrors.organization}
          onChange={(choice: Organization) => {
            updateViewModelOrganization(choice, verificationService);
            updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
          }}
          value={orgToOption(
            (verificationService.viewModel as LowIncomePersonalInfoViewModel).organization,
          )}
          verificationService={verificationService}
          isRequired
        />
      )}
      <div className="sid-names">
        <FirstName
          value={viewModel.firstName}
          isErrored={!!fieldValidationErrors.firstName}
          onChange={(newValue) => {
            updateLowIncomeViewModel("firstName", newValue);
            updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
          }}
        />
        <LastName
          value={viewModel.lastName}
          isErrored={!!fieldValidationErrors.lastName}
          onChange={(newValue) => {
            updateLowIncomeViewModel("lastName", newValue);
            updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
          }}
        />
      </div>
      <BirthDate
        isErrored={!!fieldValidationErrors.birthDate}
        errorId={fieldValidationErrors.birthDate}
        locale={locale}
        onChange={(newValue) => {
          updateLowIncomeViewModel("birthDate", newValue);
          updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
        }}
        value={viewModel.birthDate}
      />
      <AddressComponent
        isErrored={!!fieldValidationErrors.address1}
        errorId={fieldValidationErrors.address1}
        onChange={(newValue) => {
          updateLowIncomeViewModel("address1", newValue);
          updateFieldValidationErrorsByFieldId("address1", newValue, verificationService);
        }}
        value={viewModel.address1}
        isRequired
        explanation={
          <FormattedHTMLMessage id="addressExplanation" defaultMessage="Use your home address" />
        }
      />
      <div className="sid-address-extra-fields sid-thirds">
        <CityComponent
          isErrored={!!fieldValidationErrors.city}
          errorId={fieldValidationErrors.city}
          onChange={(newValue) => {
            updateLowIncomeViewModel("city", newValue);
            updateFieldValidationErrorsByFieldId("city", newValue, verificationService);
          }}
          value={viewModel.city}
          isRequired
        />
        <StateSelectComponent
          isRequired
          options={getAvailableStateChoices(verificationService.programTheme, intl)}
          value={viewModel.state || undefined}
          isErrored={Boolean(fieldValidationErrors.state)}
          onChange={async (stateChoice: FormSelectChoice<State, string>) => {
            const stateValue = stateChoice ? stateChoice.value : undefined;
            updateLowIncomeViewModel("state", stateValue);
            updateFieldValidationErrorsByFieldId("state", stateValue, verificationService);
          }}
        />
        <PostalCodeComponent
          isErrored={!!fieldValidationErrors.postalCode}
          onChange={(newValue) => {
            updateLowIncomeViewModel("postalCode", newValue);
            updateFieldValidationErrorsByFieldId("postalCode", newValue, verificationService);
          }}
          value={viewModel.postalCode}
        />
      </div>
      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        explanation={<FormattedHTMLMessage id="emailExplanation" defaultMessage=" " />}
        onChange={(newValue) => {
          updateLowIncomeViewModel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event) => handleEmailOnKeyDown(event)}
      />
      {verificationService.programTheme.isSmsNotifierConfigured ||
      verificationService.programTheme.smsLoopEnabled ? (
        <PhoneNumber
          isRequired={!!verificationService.programTheme.smsLoopEnabled}
          value={viewModel.phoneNumber}
          isErrored={!!fieldValidationErrors.phoneNumber}
          onChange={(newValue) => {
            updateLowIncomeViewModel("phoneNumber", newValue);
            updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
          }}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      ) : null}
      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updateLowIncomeViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
        <div className="sid-low-income-submit sid-submit">
          <button
            id="sid-submit-btn-collect-info"
            onClick={() =>
              submitForm(
                viewModel,
                verificationService,
                VerificationStepsEnum.collectLowIncomePersonalInfo,
              )
            }
            type="submit"
            className={`sid-btn sid-low-income-submit-btn sid-btn--dark sid-l-full-width ${
              !isFormFilled(viewModel, verificationService.formValidationOptions)
                ? "sid-btn--disabled-like"
                : ""
            }`}
            aria-labelledby="verify-status-text"
            ref={(button) => setRef("submitButton", button)}
          >
            <span id="verify-status-text">
              {failedInstantMatch ? (
                <FormattedHTMLMessage id="tryAgain" defaultMessage="Try Again" />
              ) : (
                <FormattedHTMLMessage id="verifyMyLowIncomeStatus" defaultMessage="Verify Status" />
              )}
            </span>
          </button>
        </div>
      </div>

      <FormFooter verificationService={verificationService} />
    </div>
  );
};

export const StepLowIncomePersonalInfoComponent = injectIntl(StepLowIncomePersonalInfo);
