/**
 * TODO - preamble
 */
import React from "react";
import { FormattedHTMLMessage, InjectedIntl, injectIntl } from "react-intl";

import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { isFormFilled } from "../../lib/validators/validators";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";
import { setRef } from "../../lib/refs/refs";
import { getSafe } from "../../lib/utils/objects";
import {
  VerificationService,
  AgePersonalInfoViewModel,
  AgePersonalInfoResponse,
  FormSelectChoice,
  Country,
  CollectFieldsResponse,
  FieldDescription,
  FormFieldConfig,
} from "../../lib/types/types";

import {
  handleEmailOnKeyDown,
  submitForm,
  updateFieldValidationErrorsByFieldId,
  getAvailableCountryChoices,
  getDefaultCountryChoice,
  produceDraftViewModel,
  produceDraftViewModelWithRequiredFields,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";

import { overrideValidator } from "../../lib/validators/validatorOverride";
import {
  setCustomValidator,
  removeCustomValidator,
  customValidatorExists,
} from "../../lib/validators/customValidators";

import { HowDoesVerifyingWorkComponent as HowDoesVerifyingWork } from "../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent";
import { LogoComponent } from "../LogoComponent/LogoComponent";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";
import { PostalCodeComponent as PostalCode } from "../FormFields/PostalCode/PostalCodeComponent";
import { RewardsRemainingComponent } from "../RewardsRemaining/RewardsRemainingComponent";

import { carrierConsentValueValidator, phoneNumberValidator } from "./AgeSegmentValidation";
import { CountryComponentWrapper } from "../FormFields/Country/CountryComponentWrapper";
import { getFieldsToCollect } from "../../lib/ServerApi/VerificationApiClient";
import { logger } from "../../lib/utils/logger/logger";
import { CityComponent } from "../FormFields/City/City";
import { AddressComponent } from "../FormFields/Address/AddressComponent";
import { getOptions } from "../../options/options";
import { FormFieldCustom } from "../FormFields/FormFieldCustom/FormFieldCustom";
import { ExtraMessage } from "../ExtraMessage";
import { ChangeLocaleV2Component } from "../FormFields/ChangeLocale/ChangeLocaleComponent";

interface StepAgePersonalInfoComponentProps {
  intl: InjectedIntl;
  verificationService: VerificationService;
}

const StepAgePersonalInfo = ({ verificationService, intl }: StepAgePersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as AgePersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const verificationResponse = verificationService.verificationResponse as AgePersonalInfoResponse;
  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);
  const countryChoices: FormSelectChoice<Country, string>[] = getAvailableCountryChoices(
    verificationService.programTheme,
    intl,
  );
  const defaultCountryChoice: FormSelectChoice<Country, string> =
    getDefaultCountryChoice(countryChoices);
  const { isSmsNotifierConfigured, smsLoopEnabled } = verificationService.programTheme;
  const safeCountryValue = getSafe(() => viewModel.countryChoice.value, defaultCountryChoice.value);
  const defaultRequiredFields: FieldDescription[] =
    safeCountryValue === "US" ? [{ key: "postalCode" }] : [];

  const locale = viewModel.localeChoice.value;

  const [requiredFields, setRequiredFields] =
    React.useState<FieldDescription[]>(defaultRequiredFields);

  if (
    !fieldValidationErrors.phoneNumber &&
    verificationResponse.errorIds &&
    verificationResponse.errorIds.includes("invalidPhoneNumber")
  ) {
    verificationService.updateFieldValidationErrors({
      ...fieldValidationErrors,
      phoneNumber: "invalidPhoneNumber",
    });
  }

  const displayPhoneNumber = (country: Country, displayForSmsLoop: boolean) =>
    country !== "US" || displayForSmsLoop;

  const updateAgeViewModel = (key: keyof AgePersonalInfoViewModel, value: any) => {
    const nextState: AgePersonalInfoViewModel = produceDraftViewModel(viewModel, key, value);
    verificationService.updateViewModel(nextState);
  };

  const updateViewModelWithRequiredFields = (
    newRequiredFields: FieldDescription[],
    conditionalRequiredFieldKeys: (keyof AgePersonalInfoViewModel)[],
  ) => {
    const nextState: AgePersonalInfoViewModel = produceDraftViewModelWithRequiredFields(
      viewModel,
      newRequiredFields,
      conditionalRequiredFieldKeys,
    );
    verificationService.updateViewModel(nextState);
  };

  const isFieldRequired = (key: keyof AgePersonalInfoViewModel) =>
    requiredFields.some((field) => field.key === key);

  overrideValidator("phoneNumber", phoneNumberValidator(safeCountryValue));

  if (displayPhoneNumber(safeCountryValue, smsLoopEnabled)) {
    if (!customValidatorExists("carrierConsentValue")) {
      setCustomValidator("carrierConsentValue", carrierConsentValueValidator(safeCountryValue));
    }
  } else if (customValidatorExists("carrierConsentValue")) {
    removeCustomValidator("carrierConsentValue");
  }

  const onCarrierConsentChange = (newValue) => {
    updateAgeViewModel("metadata", {
      ...verificationService.viewModel.metadata,
      carrierConsentValue: newValue,
    });
    updateFieldValidationErrorsByFieldId("carrierConsentValue", newValue, verificationService);
  };

  React.useEffect(() => {
    (async () => {
      try {
        const collectFieldsResponse: CollectFieldsResponse = await getFieldsToCollect(
          verificationResponse.verificationId,
          verificationResponse.currentStep,
          viewModel,
        );
        const newRequiredFields = getSafe(() => collectFieldsResponse.fieldsToCollect.required, []);
        updateViewModelWithRequiredFields(newRequiredFields, ["postalCode", "city", "address1"]);
        setRequiredFields(newRequiredFields);
      } catch (error) {
        logger.error(`Failed to determine fields to collect: ${error}`);
      }
    })();
  }, [viewModel.country]);

  return (
    <div
      id="sid-step-student-personal-info"
      className="sid-age-personal-info-container sid-l-container"
    >
      {failedInstantMatch ? (
        <div className="sid-header">
          <div className="sid-age-logo-container sid-l-horz-center">
            <LogoComponent verificationService={verificationService} />
          </div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.tryAgain.title"
              defaultMessage="Check Your Name & Birthdate"
              tagName="h1"
            />
          </div>
          <div className="sid-header__subtitle sid-l-horz-center sid-header__subtitle--error">
            <FormattedHTMLMessage
              id="step.personalInfo.tryAgain.subtitle"
              defaultMessage="Your full name and birthdate must match what is on your government-issued documents."
            />
          </div>
        </div>
      ) : (
        <div className="sid-header">
          <div className="sid-age-logo-container sid-l-horz-center">
            <LogoComponent verificationService={verificationService} />
          </div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.title"
              defaultMessage="Unlock Your Special Offer"
              tagName="h1"
            />
          </div>
          <div className="sid-header__subtitle sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.subtitle"
              defaultMessage="Verify your current age."
            />
          </div>
          <div className="sid-header__how-verifying-works sid-l-horz-center">
            <HowDoesVerifyingWork verificationService={verificationService} />
            <RewardsRemainingComponent verificationService={verificationService} />
          </div>
        </div>
      )}

      <ChangeLocaleV2Component verificationService={verificationService} />
      <CountryComponentWrapper
        verificationService={verificationService}
        viewModelDraftDecorator={(draft, countryChoice) => {
          const viewModelDraft = draft as AgePersonalInfoViewModel;
          const country = countryChoice ? countryChoice.value : undefined;

          if (!displayPhoneNumber(country, smsLoopEnabled)) {
            viewModelDraft.phoneNumber = "";
          }
        }}
      />

      <div className="sid-names">
        <FirstName
          value={viewModel.firstName}
          isErrored={!!fieldValidationErrors.firstName}
          onChange={(newValue) => {
            updateAgeViewModel("firstName", newValue);
            updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
          }}
        />

        <LastName
          value={viewModel.lastName}
          isErrored={!!fieldValidationErrors.lastName}
          onChange={(newValue) => {
            updateAgeViewModel("lastName", newValue);
            updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
          }}
        />
      </div>
      <BirthDate
        isErrored={!!fieldValidationErrors.birthDate}
        errorId={fieldValidationErrors.birthDate}
        locale={locale}
        onChange={(newValue) => {
          updateAgeViewModel("birthDate", newValue);
          updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
        }}
        value={viewModel.birthDate}
      />
      {isFieldRequired("city") && (
        <CityComponent
          value={viewModel.city}
          isRequired
          isErrored={!!fieldValidationErrors.city}
          errorId={fieldValidationErrors.city}
          onChange={(value) => {
            updateAgeViewModel("city", value);
            updateFieldValidationErrorsByFieldId("city", value, verificationService);
          }}
        />
      )}
      {isFieldRequired("address1") && (
        <AddressComponent
          value={viewModel.address1}
          isRequired
          isErrored={!!fieldValidationErrors.address1}
          errorId={fieldValidationErrors.address1}
          onChange={(value) => {
            updateAgeViewModel("address1", value);
            updateFieldValidationErrorsByFieldId("address1", value, verificationService);
          }}
        />
      )}
      {isFieldRequired("postalCode") && (
        <PostalCode
          isErrored={!!fieldValidationErrors.postalCode}
          onChange={(newValue) => {
            updateAgeViewModel("postalCode", newValue);
            updateFieldValidationErrorsByFieldId("postalCode", newValue, verificationService);
          }}
          value={viewModel.postalCode}
        />
      )}
      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        onChange={(newValue) => {
          updateAgeViewModel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event) => handleEmailOnKeyDown(event)}
      />
      {(displayPhoneNumber(safeCountryValue, smsLoopEnabled) || isSmsNotifierConfigured) && (
        <PhoneNumber
          value={viewModel.phoneNumber}
          isRequired={verificationService.programTheme.smsLoopEnabled || safeCountryValue !== "US"}
          isErrored={!!fieldValidationErrors.phoneNumber}
          displaySmsConsent={isSmsNotifierConfigured}
          onChange={(value) => {
            updateAgeViewModel("phoneNumber", value);
            updateFieldValidationErrorsByFieldId("phoneNumber", value, verificationService);
          }}
          errorId={fieldValidationErrors.phoneNumber}
          requireCarrierConsent={
            verificationService.programTheme.smsLoopEnabled || safeCountryValue !== "US"
          }
          onCarrierConsentChange={onCarrierConsentChange}
          carrierConsent={Boolean(viewModel.metadata && viewModel.metadata.carrierConsentValue)}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      )}

      {getOptions().customFormFields.map((config: FormFieldConfig) => (
        <FormFieldCustom config={config} verificationService={verificationService} />
      ))}

      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updateAgeViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <ExtraMessage verificationService={verificationService} suffix="CollectAboveSubmit" />

      <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
        <div className="sid-submit sid-age-personal-info-submit">
          <button
            id="sid-submit-btn-collect-info"
            onClick={() =>
              submitForm(
                viewModel,
                verificationService,
                VerificationStepsEnum.collectAgePersonalInfo,
              )
            }
            type="submit"
            className={`sid-btn sid-btn--dark sid-age-personal-info-btn sid-l-full-width ${
              !isFormFilled(viewModel, verificationService.formValidationOptions)
                ? "sid-btn--disabled-like"
                : ""
            }`}
            aria-labelledby="verify-status-text"
            ref={(button) => setRef("submitButton", button)}
          >
            <span id="verify-status-text">
              {failedInstantMatch ? (
                <FormattedHTMLMessage id="tryAgain" defaultMessage="Try Again" />
              ) : (
                <FormattedHTMLMessage id="verifyAndContinue" defaultMessage="Verify and continue" />
              )}
            </span>
          </button>
        </div>
      </div>

      <FormFooter verificationService={verificationService} />
    </div>
  );
};

export const StepAgePersonalInfoComponent = injectIntl(StepAgePersonalInfo);
