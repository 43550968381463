import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import {
  FormFieldComponentProps,
  VerificationServiceProps,
  Country,
  FormSelectChoice,
} from "../../../lib/types/types";
import { getSafe } from "../../../lib/utils/objects";
import { FormFieldTypeahead } from "../FormFieldTypeahead/FormFieldTypeahead";

export const FirstResponderOrganization = ({
  onChange,
  value,
  isErrored,
  verificationService,
  intl,
  placeholder = "",
}: FormFieldComponentProps & VerificationServiceProps) => {
  const { programId } = verificationService;
  const htmlId = "first-responder-organization";
  const country = getSafe<Country>(() => verificationService.viewModel.countryChoice.value);

  return (
    <FormFieldTypeahead
      fieldId={htmlId}
      value={value}
      onChange={(choice: FormSelectChoice) => onChange(choice)}
      placeholder={
        placeholder ||
        intl.formatHTMLMessage({ id: "organizationPlaceholder", defaultMessage: "Organization" })
      }
      label={<FormattedHTMLMessage id="organization" defaultMessage="Organization" />}
      errorMessage={
        isErrored && (
          <FormattedHTMLMessage
            id="errorId.invalidOrganization"
            defaultMessage="You must select an organization from the list"
          />
        )
      }
      verificationService={verificationService}
      showPlaceholderAndHideLabel={!!placeholder}
      country={country}
      programId={programId}
      isRequired
    />
  );
};

export const FirstResponderOrganizationComponent = injectIntl(FirstResponderOrganization);
