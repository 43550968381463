import React from "react";
import { FormattedHTMLMessage } from "react-intl";

import { VerificationService, SuccessResponse } from "../../lib/types/types";

import { LogoComponent } from "../LogoComponent/LogoComponent";
import { CopyToClipboard } from "../CopyToClipboard/CopyToClipboardComponent";
import { getBarcodeUrl } from "../../lib/ServerApi/ApiUrls";
import { RewardDisplayEnum } from "../../lib/types/runtimeTypes";

interface StepSuccessProps {
  verificationService: VerificationService;
}

const redirect = (redirectDestination: string) => {
  const win = window.open(redirectDestination, "_parent");
  win.focus();
};

const StepSuccess = ({ verificationService }: StepSuccessProps) => {
  const redirectDestination = (verificationService.verificationResponse as SuccessResponse)
    .redirectUrl;
  const showRedirect = Boolean(redirectDestination);
  const rewardCode =
    (verificationService.verificationResponse as SuccessResponse).rewardCode || "${rewardCode}"; // eslint-disable-line no-template-curly-in-string
  const noCodeType = verificationService.programTheme.config.offerType === "noCode";

  const { verificationId } = verificationService.verificationResponse;

  const { rewardDisplay } = verificationService.programTheme.config;
  const showReward = !noCodeType && !!rewardDisplay.length;

  return (
    <div id="sid-step-success" className="sid-success-container sid-l-container">
      <div className="sid-header sid-l-horz-center sid-l-space-top-md">
        <div className="sid-success-logo-container sid-l-horz-center">
          <LogoComponent
            verificationService={verificationService}
            fallbackLogo={
              <img
                className="sid-success-logo-default sid-l-lead-image"
                alt=""
                src="https://assets-resources.sheerid.com/common/images/2018/icons/circle-check.svg"
              />
            }
          />
        </div>
        <div
          className="sid-header__title sid-l-horz-center sid-l-space-top-md"
          role="alert"
          aria-live="assertive"
        >
          <FormattedHTMLMessage
            id="step.success.title"
            defaultMessage="Success! You've been verified."
            tagName="h1"
          />
        </div>
        <div className="sid-header__subtitle sid-l-horz-center sid-l-two-thirds-width">
          <FormattedHTMLMessage
            id="step.success.subtitle"
            defaultMessage={`Here is your personal coupon code.
                        It is unique to you and can only be used once.`}
          />
        </div>
      </div>

      {showReward && (
        <div className="sid-l-horz-center sid-reward-container sid-l-space-top-md">
          {rewardDisplay.map((displayType) => {
            if (displayType !== RewardDisplayEnum.TEXT)
              return (
                <img
                  key={displayType}
                  className="sid-l-space-top-md sid-barcode"
                  alt={rewardCode}
                  src={getBarcodeUrl(verificationId, "rewardCode", displayType)}
                />
              );
            return (
              <>
                <div className="sid-reward-code sid-l-space-top-md sid-h-jumbo-text sid-l-horz-center">
                  {rewardCode}
                </div>
                <div className="sid-l-space-top-md sid-h-bold-text sid-l-horz-center">
                  <CopyToClipboard
                    textToCopy={rewardCode}
                    notificationText={<FormattedHTMLMessage id="copied" defaultMessage="Copied" />}
                  >
                    <FormattedHTMLMessage
                      id="step.success.copyCouponCode"
                      defaultMessage="Copy Coupon code"
                    />
                  </CopyToClipboard>
                </div>
              </>
            );
          })}
        </div>
      )}

      <div
        className={`sid-footer sid-success-footer sid-l-horz-center ${
          noCodeType ? "sid-l-space-top-md" : "sid-l-space-top-lg"
        }`}
      >
        <FormattedHTMLMessage
          id="step.success.emailNotification"
          defaultMessage="We have emailed you a copy so you can use your code now or later. Look for the email from verify@sheerid.com"
        />
      </div>

      {showRedirect ? (
        <div className="sid-submit sid-success-submit sid-l-space-top-md">
          <button
            type="button"
            onClick={() => redirect(redirectDestination)}
            className="sid-btn sid-success-redirect-btn sid-btn--dark"
          >
            <FormattedHTMLMessage
              id="step.success.redirectButtonText"
              defaultMessage="Use code now"
            />
          </button>
        </div>
      ) : (
        ""
      )}

      <div className="sid-footer sid-success-verification-only-footer sid-l-horz-center sid-l-space-top-lg sid-h-small-text">
        <FormattedHTMLMessage id="companyName" defaultMessage="{Company}">
          {(companyName) => (
            <FormattedHTMLMessage
              id="step.success.verificationOnly"
              defaultMessage={`
                                SheerID handles verification only.
                                Now that you've been approved, please direct all questions about the
                                promotion terms to {companyName} customer service.
                            `}
              values={{ companyName }}
            />
          )}
        </FormattedHTMLMessage>
      </div>
    </div>
  );
};

export const StepSuccessComponent = StepSuccess;
