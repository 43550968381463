import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { produce } from "immer";

import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { isFormFilled } from "../../lib/validators/validators";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";
import { setRef } from "../../lib/refs/refs";
import {
  FormFieldConfig,
  FormSelectChoice,
  LicensedProfessionalPersonalInfoResponse,
  LicensedProfessionalPersonalInfoViewModel,
  LicensedProfessionalStatus,
  Organization,
  VerificationService,
} from "../../lib/types/types";
import {
  handleEmailOnKeyDown,
  submitForm,
  updateFieldValidationErrorsByFieldId,
  updateViewModelOrganization,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";

import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";
import { HowDoesVerifyingWorkComponent as HowDoesVerifyingWork } from "../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { StatusComponent as LicensedProfessionalStatusComponent } from "../FormFields/Status/StatusComponent";
import { LicensedProfessionalOrganizationComponent as LicensedProfessionalOrganization } from "../FormFields/LicensedProfessionalOrganization/LicensedProfessionalOrganizationComponent";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { PostalCodeComponent } from "../FormFields/PostalCode/PostalCodeComponent";
import { LogoComponent } from "../LogoComponent/LogoComponent";
import { RewardsRemainingComponent } from "../RewardsRemaining/RewardsRemainingComponent";
import { getOptions } from "../../options/options";
import { FormFieldCustom } from "../FormFields/FormFieldCustom/FormFieldCustom";
import { ExtraMessage } from "../ExtraMessage";
import { ChangeLocaleV2Component } from "../FormFields/ChangeLocale/ChangeLocaleComponent";
import { CountryComponentWrapper } from "../FormFields/Country/CountryComponentWrapper";

interface StepLicensedProfessionalPersonalInfoComponentProps {
  verificationService: VerificationService;
  intl: any;
}

type DefaultStatusMessages = {
  [key in LicensedProfessionalStatus]: string;
};

const defaultStatusMessages: DefaultStatusMessages = {
  LICENSED_COSMETOLOGIST: "Licensed Cosmetologist",
  LICENSED_REAL_ESTATE_AGENT: "Licensed Real Estate Agent",
  VETERINARIAN: "Veterinarian",
  CHILD_CARE_WORKER: "Child Care Worker",
  LIBRARIAN: "Librarian",
  INTERIOR_DESIGNER: "Interior Designer",
  ARCHITECT: "Architect",
  GENERAL_CONTRACTOR: "General Contractor",
};

const StepLicensedProfessionalPersonalInfo = ({
  intl,
  verificationService,
}: StepLicensedProfessionalPersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as LicensedProfessionalPersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const verificationResponse =
    verificationService.verificationResponse as LicensedProfessionalPersonalInfoResponse;
  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);

  const locale = viewModel.localeChoice.value;

  const updateLicensedProfessionalViewModel = (
    key: keyof LicensedProfessionalPersonalInfoViewModel,
    value: any,
  ) => {
    const nextState: LicensedProfessionalPersonalInfoViewModel = produce(
      viewModel,
      (draft: LicensedProfessionalPersonalInfoViewModel) => {
        (draft[key] as any) = value;
      },
    );
    verificationService.updateViewModel(nextState);
  };

  const getStatusLabel = (status: LicensedProfessionalStatus | undefined): string => {
    if (!status) {
      return "";
    }
    return intl.formatHTMLMessage({ id: status, defaultMessage: defaultStatusMessages[status] });
  };

  const getAvailableLicensedProfessionalStatuses = () => {
    const availableStatusesResponse = verificationResponse.availableStatuses;
    const availableStatuses: FormSelectChoice[] = [];

    if (availableStatusesResponse) {
      for (const status of availableStatusesResponse) {
        availableStatuses.push({
          value: status,
          label: getStatusLabel(status),
        });
      }
    }
    return availableStatuses;
  };

  return (
    <div
      id="sid-step-licensed-professional-personal-info"
      className="sid-licensed-professional-personal-info-container sid-l-container"
    >
      {failedInstantMatch ? (
        // TODO localize this message
        <p>Failied instant match</p>
      ) : (
        <div className="sid-header">
          <div className="sid-licensed-professional-logo-container sid-l-horz-center">
            <LogoComponent verificationService={verificationService} />
          </div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.title"
              defaultMessage="Unlock this Special Offer"
              tagName="h1"
            />
          </div>

          <div className="sid-header__subtitle sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.subtitle"
              defaultMessage="Verify you have a professional license in your field."
            />
          </div>
          <div className="sid-header__how-verifying-works sid-l-horz-center">
            <HowDoesVerifyingWork verificationService={verificationService} />
            <RewardsRemainingComponent verificationService={verificationService} />
          </div>
        </div>
      )}

      <ChangeLocaleV2Component verificationService={verificationService} />
      <CountryComponentWrapper verificationService={verificationService} />

      <LicensedProfessionalStatusComponent
        segmentId="licensed-professional"
        value={{
          value: viewModel.statuses ? viewModel.statuses[0] : "",
          label: viewModel.statuses ? getStatusLabel(viewModel.statuses[0]) : "",
        }}
        isErrored={!!fieldValidationErrors.statuses}
        options={getAvailableLicensedProfessionalStatuses()}
        onChange={(status: FormSelectChoice) => {
          const updatedStatuses = status ? [status.value as string] : [];
          updateLicensedProfessionalViewModel("statuses", updatedStatuses);
          updateFieldValidationErrorsByFieldId(
            "statuses",
            status ? (status.value as string) : "",
            verificationService,
          );
        }}
      />
      <LicensedProfessionalOrganization
        isErrored={!!fieldValidationErrors.organization}
        onChange={(choice: Organization) => {
          updateViewModelOrganization(choice, verificationService);
          updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
        }}
        value={
          (verificationService.viewModel as LicensedProfessionalPersonalInfoViewModel).organization
        }
        verificationService={verificationService}
      />
      <div className="sid-names">
        <FirstName
          value={viewModel.firstName}
          isErrored={!!fieldValidationErrors.firstName}
          onChange={(newValue) => {
            updateLicensedProfessionalViewModel("firstName", newValue);
            updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
          }}
        />

        <LastName
          value={viewModel.lastName}
          isErrored={!!fieldValidationErrors.lastName}
          onChange={(newValue) => {
            updateLicensedProfessionalViewModel("lastName", newValue);
            updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
          }}
        />
      </div>
      <BirthDate
        isErrored={!!fieldValidationErrors.birthDate}
        errorId={fieldValidationErrors.birthDate}
        locale={locale}
        onChange={(newValue) => {
          updateLicensedProfessionalViewModel("birthDate", newValue);
          updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
        }}
        value={viewModel.birthDate}
      />
      <PostalCodeComponent
        isErrored={!!fieldValidationErrors.postalCode}
        onChange={(newValue) => {
          updateLicensedProfessionalViewModel("postalCode", newValue);
          updateFieldValidationErrorsByFieldId("postalCode", newValue, verificationService);
        }}
        value={viewModel.postalCode}
        explanation={
          <FormattedHTMLMessage
            id="postalCodeExplanation"
            defaultMessage="Use the location on file with your licensing body"
          />
        }
      />
      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        explanation={
          <FormattedHTMLMessage
            id="emailExplanation"
            defaultMessage="Needed to send you your unique code"
          />
        }
        onChange={(newValue) => {
          updateLicensedProfessionalViewModel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event) => handleEmailOnKeyDown(event)}
      />
      {verificationService.programTheme.isSmsNotifierConfigured ||
      verificationService.programTheme.smsLoopEnabled ? (
        <PhoneNumber
          isRequired={!!verificationService.programTheme.smsLoopEnabled}
          value={viewModel.phoneNumber}
          isErrored={!!fieldValidationErrors.phoneNumber}
          onChange={(newValue) => {
            updateLicensedProfessionalViewModel("phoneNumber", newValue);
            updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
          }}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      ) : null}

      {getOptions().customFormFields.map((config: FormFieldConfig) => (
        <FormFieldCustom config={config} verificationService={verificationService} />
      ))}

      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updateLicensedProfessionalViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <ExtraMessage verificationService={verificationService} suffix="CollectAboveSubmit" />

      <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
        <div className="sid-licensed-professional-submit sid-submit">
          <button
            id="sid-submit-btn-collect-info"
            onClick={() =>
              submitForm(
                viewModel,
                verificationService,
                VerificationStepsEnum.collectLicensedProfessionalPersonalInfo,
              )
            }
            type="submit"
            className={`sid-btn sid-licensed-professional-submit-btn sid-btn--dark sid-l-full-width ${
              !isFormFilled(viewModel, verificationService.formValidationOptions)
                ? "sid-btn--disabled-like"
                : ""
            }`}
            aria-labelledby="verify-status-text"
            ref={(button) => setRef("submitButton", button)}
          >
            <span id="verify-status-text">
              {failedInstantMatch ? (
                <FormattedHTMLMessage id="tryAgain" defaultMessage="Try Again" />
              ) : (
                <FormattedHTMLMessage
                  id="verifyMyLicensedProfessionalStatus"
                  defaultMessage="Verify My Licensed Professional Status"
                />
              )}
            </span>
          </button>
        </div>
      </div>

      <FormFooter verificationService={verificationService} />
    </div>
  );
};

export const StepLicensedProfessionalPersonalInfoComponent = injectIntl(
  StepLicensedProfessionalPersonalInfo,
);
