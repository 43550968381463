import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormSelectChoice, InputSelectComponentProps } from "../../../lib/types/types";
import { FormFieldInputSelect } from "../FormFieldInputSelect/FormFieldInputSelect";

export const Country = ({
  value,
  isErrored,
  options,
  onChange,
  onKeyDown,
  intl,
  placeholder = "",
  isRequired = true,
}: InputSelectComponentProps) => {
  const inputId = "country";
  return (
    <FormFieldInputSelect
      fieldId={inputId}
      options={options}
      onChange={(country: FormSelectChoice) => onChange(country)}
      onKeyDown={onKeyDown}
      placeholder={
        placeholder ||
        intl.formatHTMLMessage({ id: "countryPlaceholder", defaultMessage: "Country" })
      }
      value={options.find((option) => option.value === (value && value.value))}
      isRequired={isRequired}
      label={<FormattedHTMLMessage id="country" defaultMessage="Country" />}
      errorMessage={
        isErrored && (
          <FormattedHTMLMessage id="errorId.invalidCountry" defaultMessage="Invalid country" />
        )
      }
      showPlaceholderAndHideLabel={!!placeholder}
    />
  );
};

export const CountryComponent = injectIntl(Country);
