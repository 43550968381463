/**
 * TODO - preamble
 */
import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { produce } from "immer";

import {
  VerificationStepsEnum,
  MilitaryStatusDefaultMessagesEnum,
} from "../../lib/types/runtimeTypes";
import { isFormFilled } from "../../lib/validators/validators";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";
import { setRef, resetRefs } from "../../lib/refs/refs";
import { logger } from "../../lib/utils/logger/logger";
import {
  VerificationService,
  FormSelectChoice,
  Organization,
  InactiveMilitaryPersonalInfoViewModel,
  InactiveMilitaryPersonalInfoResponse,
  FormFieldConfig,
} from "../../lib/types/types";
import {
  handleEmailOnKeyDown,
  submitForm,
  updateFieldValidationErrorsByFieldId,
  updateViewModelOrganization,
  getAvailableMilitaryStatuses,
  getStatusLabel,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";

import { HowDoesVerifyingWorkComponent as HowDoesVerifyingWork } from "../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent";
import { LogoComponent } from "../LogoComponent/LogoComponent";
import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { StatusComponent as MilitaryStatus } from "../FormFields/Status/StatusComponent";
import { BranchOfServiceComponent as BranchOfService } from "../FormFields/BranchOfService/BranchOfServiceComponent";
import { DischargeDateComponent as DischargeDate } from "../FormFields/DischargeDate/DischargeDateComponent";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { CountryComponentWrapper } from "../FormFields/Country/CountryComponentWrapper";
import { RewardsRemainingComponent } from "../RewardsRemaining/RewardsRemainingComponent";
import { updateMilitaryViewModel } from "./militaryStepHelpers";

import { FormFieldCustom } from "../FormFields/FormFieldCustom/FormFieldCustom";
import { getOptions } from "../../options/options";
import { ExtraMessage } from "../ExtraMessage";
import { ChangeLocaleV2Component } from "../FormFields/ChangeLocale/ChangeLocaleComponent";
import { ActiveDutyStartDateComponent } from "../FormFields/ActiveDutyStartDate/ActiveDutyStartDateComponent";

interface StepInactiveMilitaryPersonalInfoComponentProps {
  intl: any;
  verificationService: VerificationService;
}

export const StepInactiveMilitaryPersonalInfo = ({
  intl,
  verificationService,
}: StepInactiveMilitaryPersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as InactiveMilitaryPersonalInfoViewModel;
  const previousViewModel =
    verificationService.previousViewModel as InactiveMilitaryPersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const verificationResponse =
    verificationService.verificationResponse as InactiveMilitaryPersonalInfoResponse;
  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);

  const availableStatuses = getAvailableMilitaryStatuses(verificationService, intl);
  const locale = viewModel.localeChoice.value;

  const updateInactiveMilitaryViewModel = (
    key: keyof InactiveMilitaryPersonalInfoViewModel,
    value: any,
  ) => {
    verificationService.updateViewModel(
      updateMilitaryViewModel(key, value, verificationService, availableStatuses),
    );
  };

  const submitStep = () => {
    const nextState = produce(viewModel, () => {});
    logger.info("StepMilitaryStatusComponent submitting form");
    verificationService.submitStep(
      VerificationStepsEnum.collectMilitaryStatus,
      nextState,
      verificationService.previousVerificationResponse || verificationService.verificationResponse,
    );
  };

  if (
    (viewModel.status && previousViewModel && previousViewModel.status !== viewModel.status) ||
    (viewModel.status &&
      verificationService.verificationResponse.currentStep ===
        VerificationStepsEnum.collectMilitaryStatus)
  ) {
    submitStep();
  }

  // Refs should be reset to ensure the field order isn't affected by the previous step
  resetRefs();

  return (
    <div
      id="sid-step-inactive-military-personal-info"
      className="sid-inactive-military-personal-info-container sid-military-personal-info-container sid-l-container"
    >
      {failedInstantMatch ? (
        <div className="sid-header">
          <div className="sid-inactive-military-personal-info-logo-container sid-military-personal-info-logo-container sid-l-horz-center">
            <LogoComponent verificationService={verificationService} />
          </div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.tryAgain.title"
              defaultMessage="Check Your Name & Birthdate"
              tagName="h1"
            />
          </div>

          <div className="sid-header__subtitle sid-l-horz-center sid-header__subtitle--error">
            <FormattedHTMLMessage
              id="step.personalInfo.tryAgain.subtitle"
              defaultMessage="
                                Enter your first and last name on the form as it appears on your military document(s).
                                Keep in mind that may be your given name."
            />
          </div>
        </div>
      ) : (
        <div className="sid-header">
          <div className="sid-inactive-military-personal-info-logo-container sid-military-personal-info-logo-container sid-l-horz-center">
            <LogoComponent verificationService={verificationService} />
          </div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.title"
              defaultMessage="Unlock this Military-Only Offer"
              tagName="h1"
            />
          </div>

          <div className="sid-header__subtitle sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.subtitle"
              defaultMessage="Verify you're a member of the armed forces."
            />
          </div>
          <div className="sid-header__how-verifying-works sid-l-horz-center">
            <HowDoesVerifyingWork verificationService={verificationService} />
            <RewardsRemainingComponent verificationService={verificationService} />
          </div>
        </div>
      )}

      <ChangeLocaleV2Component verificationService={verificationService} />
      <CountryComponentWrapper verificationService={verificationService} />

      {availableStatuses ? (
        <MilitaryStatus
          segmentId="military"
          value={{
            value: viewModel.status,
            label: getStatusLabel(intl, viewModel.status, MilitaryStatusDefaultMessagesEnum),
          }}
          isErrored={!!fieldValidationErrors.status}
          errorId="invalidMilitaryStatus"
          options={availableStatuses}
          onChange={(status: FormSelectChoice) => {
            updateInactiveMilitaryViewModel("status", status ? (status.value as string) : "");
            updateFieldValidationErrorsByFieldId(
              "status",
              status ? (status.value as string) : "",
              verificationService,
            );
          }}
        />
      ) : null}

      <BranchOfService
        isErrored={!!fieldValidationErrors.organization}
        onChange={(choice: Organization) => {
          updateViewModelOrganization(choice, verificationService);
          updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
        }}
        value={{
          value: (verificationService.viewModel as InactiveMilitaryPersonalInfoViewModel)
            .organization.id,
          label: (verificationService.viewModel as InactiveMilitaryPersonalInfoViewModel)
            .organization.name,
        }}
        verificationService={verificationService}
        organizations={verificationService.orgList}
      />

      <div className="sid-names">
        <FirstName
          value={viewModel.firstName}
          isErrored={!!fieldValidationErrors.firstName}
          onChange={(newValue) => {
            updateInactiveMilitaryViewModel("firstName", newValue);
            updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
          }}
        />

        <LastName
          value={viewModel.lastName}
          isErrored={!!fieldValidationErrors.lastName}
          onChange={(newValue) => {
            updateInactiveMilitaryViewModel("lastName", newValue);
            updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
          }}
        />
      </div>

      <BirthDate
        isErrored={!!fieldValidationErrors.birthDate}
        errorId={fieldValidationErrors.birthDate}
        locale={locale}
        onChange={(newValue) => {
          updateInactiveMilitaryViewModel("birthDate", newValue);
          updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
        }}
        value={viewModel.birthDate}
      />

      {verificationService.programTheme.strictMilitaryValidationEnabled && (
        <ActiveDutyStartDateComponent
          isErrored={!!fieldValidationErrors.activeDutyStartDate}
          locale={locale}
          onChange={(newValue) => {
            updateInactiveMilitaryViewModel("activeDutyStartDate", newValue);
            updateFieldValidationErrorsByFieldId(
              "activeDutyStartDate",
              newValue,
              verificationService,
            );
          }}
          value={viewModel.activeDutyStartDate}
        />
      )}

      <DischargeDate
        isErrored={!!fieldValidationErrors.dischargeDate}
        onChange={(newValue) => {
          updateInactiveMilitaryViewModel("dischargeDate", newValue);
          updateFieldValidationErrorsByFieldId("dischargeDate", newValue, verificationService);
        }}
        value={viewModel.dischargeDate}
      />

      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        explanation={
          <FormattedHTMLMessage
            id="emailExplanation"
            defaultMessage="Personal email address is recommended"
          />
        }
        onChange={(newValue) => {
          updateInactiveMilitaryViewModel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event) => handleEmailOnKeyDown(event)}
      />
      {verificationService.programTheme.isSmsNotifierConfigured ||
      verificationService.programTheme.smsLoopEnabled ? (
        <PhoneNumber
          isRequired={!!verificationService.programTheme.smsLoopEnabled}
          value={viewModel.phoneNumber}
          isErrored={!!fieldValidationErrors.phoneNumber}
          onChange={(newValue) => {
            updateInactiveMilitaryViewModel("phoneNumber", newValue);
            updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
          }}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      ) : null}

      {getOptions().customFormFields.map((config: FormFieldConfig) => (
        <FormFieldCustom config={config} verificationService={verificationService} />
      ))}

      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updateInactiveMilitaryViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <ExtraMessage verificationService={verificationService} suffix="CollectAboveSubmit" />

      <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
        <div className="sid-inactive-military-submit sid-military-submit sid-submit">
          <button
            id="sid-submit-btn-collect-info"
            onClick={() =>
              submitForm(
                viewModel,
                verificationService,
                VerificationStepsEnum.collectInactiveMilitaryPersonalInfo,
              )
            }
            type="submit"
            className={`sid-btn sid-inactive-military-submit-btn sid-military-submit-btn sid-btn--dark sid-l-full-width ${
              !isFormFilled(viewModel, verificationService.formValidationOptions)
                ? "sid-btn--disabled-like"
                : ""
            }`}
            aria-labelledby="verify-status-text"
            ref={(button) => setRef("submitButton", button)}
          >
            <span id="verify-status-text">
              {failedInstantMatch ? (
                <FormattedHTMLMessage id="tryAgain" defaultMessage="Try Again" />
              ) : (
                <FormattedHTMLMessage
                  id="verifyMyMilitaryStatus"
                  defaultMessage="Verify My Military Status"
                />
              )}
            </span>
          </button>
        </div>
      </div>

      <FormFooter verificationService={verificationService} />
    </div>
  );
};

export const StepInactiveMilitaryPersonalInfoComponent = injectIntl(
  StepInactiveMilitaryPersonalInfo,
);
