/**
 * Google Analytics custom event recording
 */
import {
  VerificationStep,
  ReduxState,
  DocUploadViewModel,
  VerificationResponse,
  WithCoreFields,
} from "../types/types";
import { logger } from "../utils/logger/logger";
import { VerificationStepsEnum } from "../types/runtimeTypes";

export enum isTestValues {
  TRUE = "true",
  FALSE = "false",
}

export const testRequestEmailDomains = ["sheerid.com"];

// GA admin config needs to correlate with these dimension index numbers:
export enum DimensionName {
  verificationId = "verificationId",
  programId = "programId",
  jslibVersionActual = "jslibVersionActual",
  segment = "segment",
  subSegment = "subSegment",
  testRequest = "testRequest",
  testMode = "testMode",
  age = "age",
}

enum Command {
  EVENT = "event",
  SET = "set",
}

enum EventCategory {
  VERIFICATION = "Verification",
  VIEW_EXPERIMENT = "ViewExperiment",
  PERFORMANCE = "Performance",
}

export enum PerformanceNames {
  LCP = "largestContentfulPaint",
  INITIAL_STEP_LOAD = "initialStepLoad",
}

export const recordEvent = (step: VerificationStep, stepAction: string = "") => {
  window.gtag(Command.EVENT, EventCategory.VERIFICATION, {
    step,
    step_action: stepAction,
  });
};

export const setUserId = (verificationId: string) => {
  window.gtag({ user_id: verificationId });
};

export const recordPerformanceMetric = (name: PerformanceNames, value: number) => {
  window.gtag(Command.EVENT, EventCategory.PERFORMANCE, { name, value });
};

export const recordViewModelChange = (oldState: ReduxState, newState: ReduxState) => {
  try {
    if (newState.verificationResponse.currentStep === VerificationStepsEnum.docUpload) {
      if (
        (oldState.viewModel as DocUploadViewModel).file1 === undefined &&
        (newState.viewModel as DocUploadViewModel).file1 !== undefined
      ) {
        recordEvent(VerificationStepsEnum.docUpload, "addFiles");
      }
    }
  } catch (e) {
    logger.warn("Failed addFiles event", e);
  }
};

export const recordViewExperimentEvent = (flag: string) => {
  try {
    window.gtag(Command.EVENT, EventCategory.VIEW_EXPERIMENT, {
      flag,
    });
  } catch (e) {
    logger.warn("Failed to record ViewExperiment event", e);
  }
};

export const recordVerificationResponse = (verificationResponse: VerificationResponse) => {
  try {
    const label =
      verificationResponse.errorIds && Array.isArray(verificationResponse.errorIds)
        ? verificationResponse.errorIds.sort().join(",")
        : undefined;
    setUserId(verificationResponse.verificationId);
    setDimension(DimensionName.segment, verificationResponse.segment);
    setDimension(DimensionName.subSegment, verificationResponse.subSegment);
    recordEvent(verificationResponse.currentStep, label);
  } catch (e) {
    logger.warn("Failed recordVerificationResponse event", e);
  }
};

export const setDimension = (dimensionName: DimensionName, value: string) => {
  try {
    window.gtag(Command.SET, "user_properties", {
      [dimensionName]: value,
    });
  } catch (e) {
    logger.warn(`Failed to set ${dimensionName}`, e);
  }
};

export const setGaDimensionTestRequest = (viewModel: WithCoreFields) => {
  try {
    const { email } = viewModel;
    if (
      email &&
      testRequestEmailDomains.filter((domain) => email.indexOf(domain) > -1).length > 0
    ) {
      setDimension(DimensionName.testRequest, isTestValues.TRUE);
    }
  } catch (e) {
    logger.warn("Error setting testRequest dimension", e);
  }
};

export function observePerformanceMetrics() {
  try {
    if (PerformanceObserver.supportedEntryTypes.includes("largest-contentful-paint")) {
      const LCPObserver = new PerformanceObserver((list) => {
        const entries = list.getEntries();
        const lastEntry = entries[entries.length - 1];
        logger.log("Performance:", PerformanceNames.LCP, lastEntry.startTime);
        recordPerformanceMetric(PerformanceNames.LCP, lastEntry.startTime);
        LCPObserver.disconnect();
      });
      LCPObserver.observe({ type: "largest-contentful-paint", buffered: true });
    }

    if (PerformanceObserver.supportedEntryTypes.includes("mark")) {
      const stepRenderObserver = new PerformanceObserver((list) => {
        let entries = list.getEntries();
        entries = entries.filter((e) => e.name === PerformanceNames.INITIAL_STEP_LOAD);
        const firstEntry = entries[0];
        if (firstEntry) {
          logger.log("Performance:", PerformanceNames.INITIAL_STEP_LOAD, firstEntry.startTime);
          recordPerformanceMetric(PerformanceNames.INITIAL_STEP_LOAD, firstEntry.startTime);

          stepRenderObserver.disconnect();
        }
      });
      stepRenderObserver.observe({ type: "mark", buffered: true });
    }
  } catch (e) {
    logger.warn("Error setting up observePerformanceMetrics", e);
  }
}
