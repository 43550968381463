import React, { ReactNode } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { logger } from "../../lib/utils/logger/logger";
import { FormFieldComponentProps, FormSelectChoice } from "../../lib/types/types";
import { InputSelectComponent } from "../FormInputs/InputSelect/InputSelectComponent";
import { InputTextComponent } from "../FormInputs/InputText/InputText";
import { formatTwoDigitValues } from "../../lib/utils/datesFormat/formatTwoDigitValues";
import { FieldLabel } from "../FieldLabel";
import { DEFAULT_LOCALE } from "../../constants";
import { isDayFirst } from "./isDayFirst";

export interface DateComponentProps {
  label: ReactNode;
  explanation?: ReactNode;
  errorMsg: ReactNode;
  fieldName: string;
  fieldId: string;
  mode?: "date" | "month";
  locale?: string;
}

export const DateInput = ({
  onChange,
  intl,
  isErrored,
  isRequired = true,
  value = "2000-01-01",
  errorMsg,
  label,
  explanation,
  fieldName,
  fieldId,
  mode = "date",
  locale = DEFAULT_LOCALE,
}: Omit<FormFieldComponentProps, "errorId"> & InjectedIntlProps & DateComponentProps) => {
  const cssId = `sid-${fieldId}`;
  const errorHtmlId = `sid-${fieldId}-error`;
  const dateParts = value.split("-");
  const dateYear = Number.parseInt(dateParts[0], 10) || undefined;
  const dateMonth = Number.parseInt(dateParts[1], 10) || undefined;
  // Default day to 1 if in month mode (where days are ignored) so that we return valid date strings.
  const dateDay = mode === "month" ? 1 : Number.parseInt(dateParts[2], 10) || undefined;

  const minYear = 1900;
  const thisYear = new Date().getFullYear();

  /* prettier-ignore */
  const months: FormSelectChoice[] = [
    { value: "1", label: intl.formatHTMLMessage({ id: "dateTime.january", defaultMessage: "January" }) },
    { value: "2", label: intl.formatHTMLMessage({ id: "dateTime.february", defaultMessage: "February" }) },
    { value: "3", label: intl.formatHTMLMessage({ id: "dateTime.march", defaultMessage: "March" }) },
    { value: "4", label: intl.formatHTMLMessage({ id: "dateTime.april", defaultMessage: "April" }) },
    { value: "5", label: intl.formatHTMLMessage({ id: "dateTime.may", defaultMessage: "May" }) },
    { value: "6", label: intl.formatHTMLMessage({ id: "dateTime.june", defaultMessage: "June" }) },
    { value: "7", label: intl.formatHTMLMessage({ id: "dateTime.july", defaultMessage: "July" }) },
    { value: "8", label: intl.formatHTMLMessage({ id: "dateTime.august", defaultMessage: "August" }) },
    { value: "9", label: intl.formatHTMLMessage({ id: "dateTime.september", defaultMessage: "September" }) },
    { value: "10", label: intl.formatHTMLMessage({ id: "dateTime.october", defaultMessage: "October" }) },
    { value: "11", label: intl.formatHTMLMessage({ id: "dateTime.november", defaultMessage: "November" }) },
    { value: "12", label: intl.formatHTMLMessage({ id: "dateTime.december", defaultMessage: "December" }) },
  ];

  const isDateFieldEmpty = (year, month, day) =>
    (!day || day === "") && (!month || month === "") && (!year || year === "");

  const updateMonth = (month) => {
    if (isDateFieldEmpty(dateYear, month, dateDay)) {
      onChange("");
      return;
    }

    if (month === "" || month === null) {
      onChange(`${dateYear}--${dateDay}`);
    }

    const newVal = Number.parseInt(month, 10);
    if (typeof newVal === "number" && newVal >= 1 && newVal <= 12) {
      onChange(`${dateYear}-${formatTwoDigitValues(month)}-${formatTwoDigitValues(dateDay)}`);
    } else {
      logger.warn(`Value '${newVal}' is an invalid month`);
    }
  };

  const updateDay = (day) => {
    if (isDateFieldEmpty(dateYear, dateMonth, day)) {
      onChange("");
      return;
    }

    if (day === "") {
      onChange(`${dateYear}-${dateMonth}-`);
    }
    const newVal = Number.parseInt(day, 10);
    if (typeof newVal === "number" && newVal >= 1 && newVal <= 31) {
      onChange(`${dateYear}-${formatTwoDigitValues(dateMonth)}-${formatTwoDigitValues(newVal)}`);
    } else {
      logger.warn(`Value '${newVal}' is an invalid day`);
    }
  };

  const updateBirthYear = (year) => {
    if (isDateFieldEmpty(year, dateMonth, dateDay)) {
      onChange("");
      return;
    }

    if (year === "") {
      onChange(`-${dateMonth}-${dateDay}`);
    }
    const newVal = Number.parseInt(year, 10);
    if (typeof newVal === "number" && newVal >= 1 && newVal <= thisYear) {
      onChange(`${newVal}-${formatTwoDigitValues(dateMonth)}-${formatTwoDigitValues(dateDay)}`);
    } else {
      logger.warn(`Value '${newVal}' is an invalid year`);
    }
  };

  const getDate = () => {
    if (!dateDay && !dateMonth && !dateYear) {
      onChange("");
    } else {
      onChange(`${dateYear}-${formatTwoDigitValues(dateMonth)}-${formatTwoDigitValues(dateDay)}`);
    }
  };

  const monthFieldId = `${cssId}__month`;
  const fieldLabelId = `${cssId}-label`;

  const wrappedErrMsg = (
    <div className="sid-field-error" id={errorHtmlId}>
      {errorMsg}
    </div>
  );

  const month = (
    <InputSelectComponent
      className={`${cssId}__month sid-date__month`}
      fieldId={fieldName}
      inputId={monthFieldId}
      fieldLabelId={fieldLabelId}
      ariaDescribedBy={isErrored ? errorHtmlId : ""}
      isErrored={isErrored}
      options={months}
      onChange={(choice) => {
        if (choice) {
          updateMonth(choice.value);
        } else {
          // clear the birth month
          updateMonth(null);
        }
      }}
      placeholder={intl.formatHTMLMessage({ id: "dateTime.month", defaultMessage: "Month" })}
      suppressPlaceholder={false}
      value={dateMonth ? months[dateMonth - 1] : undefined}
      isRequired={isRequired}
      buttonRef="inputSelectButtonBirthDate"
    />
  );

  const day = mode === "date" && (
    <InputTextComponent
      className={`${cssId}__day sid-date__day sid-text-input sid-text-input--required ${
        isErrored ? "sid-text-input--error" : ""
      }`}
      id={`${fieldId}-day`}
      isErrored={isErrored}
      min={1}
      max={31}
      name={`${cssId}-day`}
      onChange={(e) => updateDay(e.target.value)}
      onBlur={getDate}
      pattern="\d*"
      placeholder={intl.formatHTMLMessage({ id: "dateTime.day", defaultMessage: "Day" })}
      hidePlaceholder={false}
      aria-label={intl.formatHTMLMessage({ id: "dateTime.day", defaultMessage: "Day" })}
      aria-labelledby={cssId}
      aria-required={isRequired}
      refId={`${fieldName}Day`}
      type="text"
      value={dateDay || ""}
    />
  );

  return (
    <div className={`sid-field ${cssId}`}>
      <div className="sid-l-space-top-md" />
      <FieldLabel
        text={label}
        htmlForLabel={monthFieldId}
        id={fieldLabelId}
        displayClasses="sid-used-for-verification-purposes-label sid-field__label-with-explanation"
        isRequired={isRequired}
      >
        {explanation}
      </FieldLabel>
      <div className={`${cssId}__inputs sid-date__inputs`}>
        {isDayFirst(locale) ? (
          <>
            {day}
            {month}
          </>
        ) : (
          <>
            {month}
            {day}
          </>
        )}
        <InputTextComponent
          className={`${cssId}__year sid-date__year sid-text-input sid-text-input--required ${
            isErrored ? "sid-text-input--error" : ""
          }`}
          id={`${fieldId}-year`}
          name={`${cssId}-year`}
          type="text"
          isErrored={isErrored}
          min={minYear}
          max={thisYear}
          value={dateYear || ""}
          pattern="\d*"
          placeholder={intl.formatHTMLMessage({ id: "dateTime.year", defaultMessage: "Year" })}
          hidePlaceholder={false}
          aria-label={intl.formatHTMLMessage({ id: "dateTime.year", defaultMessage: "Year" })}
          aria-labelledby={cssId}
          aria-required={isRequired}
          refId={`${fieldName}Year`}
          onChange={(e) => updateBirthYear(e.target.value)}
          onBlur={getDate}
        />
      </div>

      {isErrored && wrappedErrMsg}
    </div>
  );
};

export const DateComponent = injectIntl(DateInput);
