/* eslint-disable indent */
// TODO fix indent
import React, { useEffect, useRef } from "react";
import Downshift from "downshift";
import {
  FormSelectChoice,
  InputSelectOnKeyDown,
  FieldId,
  ExtendedFieldId,
} from "../../../lib/types/types";
import { InputSelectListComponent } from "./InputSelectList/InputSelectListComponent";
import { InputSelectButtonComponent } from "./InputSelectButton/InputSelectButtonComponent";
import { setRef } from "../../../lib/refs/refs";

export interface InputSelectProps {
  onChange: any;
  options: FormSelectChoice[];
  fieldId: FieldId | ExtendedFieldId;
  fieldLabelId?: string;
  className?: string;
  disabled?: boolean;
  inputId?: string;
  isErrored?: boolean;
  onKeyDown?: InputSelectOnKeyDown;
  placeholder?: string;
  suppressPlaceholder?: boolean;
  value?: FormSelectChoice;
  autoFocus?: boolean;
  ariaLabel?: string;
  ariaDescribedBy?: string;
  isRequired?: boolean;
  buttonRef?: string;
  role?: string;
}

const InputSelect = ({
  className,
  inputId,
  disabled,
  fieldId,
  fieldLabelId,
  isErrored,
  options,
  onKeyDown,
  onChange,
  placeholder,
  // Most invokers want placeholder hidden by css, so it can be easily shown when
  // customizing the implementation. Birth date is an example of an exception.
  suppressPlaceholder = true,
  value,
  autoFocus,
  ariaLabel,
  ariaDescribedBy,
  isRequired,
  buttonRef,
  role = "combobox",
}: InputSelectProps) => {
  const itemToString = (item) => (item ? item.label : "");

  const filterOptions = (options: FormSelectChoice[], inputValue: string): FormSelectChoice[] => {
    const filteredOptions: FormSelectChoice[] = options.filter(
      (option) =>
        !inputValue ||
        option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.value.toString().toLowerCase().includes(inputValue.toLowerCase()),
    );
    return filteredOptions;
  };

  const handleOnBlur = (
    event: React.FormEvent<HTMLInputElement>,
    selectHighlightedItem: Function,
    selectedItem: FormSelectChoice,
  ) => {
    if (!selectedItem || event.currentTarget.value !== selectedItem.label) {
      selectHighlightedItem();
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (typeof onKeyDown === "function") {
      onKeyDown(event);
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  // NOTE: Dependency array omitted to account for use of resetRef(), as useEffect with dep arr
  // will only trigger on initial render leaving the refs empty, this replicates prior version
  // where ref callback would trigger with each re-render
  useEffect(() => {
    setRef(fieldId, inputRef.current);
  });

  return (
    <Downshift
      id={inputId}
      onChange={onChange}
      itemToString={itemToString}
      selectedItem={value && value.value && value.label ? value : ""}
      defaultHighlightedIndex={0}
    >
      {({
        clearSelection,
        getInputProps,
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        highlightedIndex,
        inputValue,
        isOpen,
        openMenu,
        selectedItem,
        selectHighlightedItem,
      }) => (
        <div className={`sid-input-select-list ${className || ""}`}>
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getInputProps({
              ref: inputRef,
              onClick: () => openMenu(),
              onBlur: (event) => handleOnBlur(event, selectHighlightedItem, selectedItem),
              onKeyDown: (event) => handleOnKeyDown(event),
            })}
            // TODO resolve next line. Product needs this to autofocus, but linter says bad for accessibility
            // eslint-disable-next-line
            autoFocus={autoFocus}
            id={inputId}
            className={`sid-l-full-width sid-text-input ${
              suppressPlaceholder ? "sid-hidden-placeholder" : ""
            } ${isErrored ? "sid-text-input--error" : ""}`}
            disabled={disabled}
            placeholder={placeholder}
            aria-required={isRequired}
            aria-labelledby={fieldLabelId}
            aria-describedby={ariaDescribedBy}
            aria-invalid={isErrored}
            role={role}
          />

          <InputSelectButtonComponent
            disabled={disabled}
            getToggleButtonProps={getToggleButtonProps}
            isOpen={isOpen}
            clearSelection={selectedItem ? clearSelection : undefined}
            buttonRef={buttonRef}
          />

          <InputSelectListComponent
            highlightedIndex={highlightedIndex}
            itemToString={itemToString}
            options={filterOptions(options, inputValue)}
            getItemProps={getItemProps}
            getMenuProps={getMenuProps}
            ariaLabel={ariaLabel}
            isOpen={isOpen}
          />
        </div>
      )}
    </Downshift>
  );
};

InputSelect.defaultProps = {
  className: undefined,
  disabled: false,
  inputId: undefined,
  isErrored: false,
  onKeyDown: undefined,
  placeholder: "",
  value: undefined,
};

export const InputSelectComponent = InputSelect;
