import { produce } from "immer";
import React, { useEffect } from "react";
import { FormattedHTMLMessage } from "react-intl";

import { shouldCollectPostalCode } from "../../lib/organization/organization";
import { setRef } from "../../lib/refs/refs";
import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import {
  FormFieldConfig,
  Organization,
  TeacherPersonalInfoResponse,
  TeacherPersonalInfoViewModel,
  VerificationService,
} from "../../lib/types/types";
import {
  handleEmailOnKeyDown,
  submitForm,
  updateFieldValidationErrorsByFieldId,
  updateViewModelOrganization,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { isFormFilled } from "../../lib/validators/validators";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";

import { getOptions } from "../../options/options";
import { ExtraMessage } from "../ExtraMessage";
import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { ChangeLocaleV2Component } from "../FormFields/ChangeLocale/ChangeLocaleComponent";
import { CountryComponentWrapper } from "../FormFields/Country/CountryComponentWrapper";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { FormFieldCustom } from "../FormFields/FormFieldCustom/FormFieldCustom";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { PostalCodeComponent } from "../FormFields/PostalCode/PostalCodeComponent";
import { TeacherSchoolComponent } from "../FormFields/TeacherSchool/TeacherSchoolComponent";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";
import { HowDoesVerifyingWorkComponent as HowDoesVerifyingWork } from "../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent";
import { LogoComponent } from "../LogoComponent/LogoComponent";
import { RewardsRemainingComponent } from "../RewardsRemaining/RewardsRemainingComponent";

interface StepTeacherPersonalInfoComponentProps {
  verificationService: VerificationService;
}

const StepTeacherPersonalInfo = ({
  verificationService,
}: StepTeacherPersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as TeacherPersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const verificationResponse =
    verificationService.verificationResponse as TeacherPersonalInfoResponse;
  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);

  const locale = viewModel.localeChoice.value;

  const updateTeacherViewModel = (key: keyof TeacherPersonalInfoViewModel, value: any) => {
    const nextState: TeacherPersonalInfoViewModel = produce(
      viewModel,
      (draft: TeacherPersonalInfoViewModel) => {
        (draft[key] as any) = value;
      },
    );
    verificationService.updateViewModel(nextState);
  };

  // Don't validate DOB if ID Check is not enabled
  useEffect(() => {
    if (!verificationService.programTheme.idCheckAddOnEnabled) {
      updateTeacherViewModel("birthDate", "");
      updateTeacherViewModel("fieldsToSkipValidation", ["birthDate"]);
    } else {
      updateTeacherViewModel("fieldsToSkipValidation", []);
    }
  }, [verificationService.programTheme.idCheckAddOnEnabled]);

  return (
    <div
      id="sid-step-teacher-personal-info"
      className="sid-teacher-personal-info-container sid-l-container"
    >
      {failedInstantMatch ? (
        <div className="sid-header">
          <div className="sid-teacher-personal-info-logo-container sid-l-horz-center">
            <LogoComponent verificationService={verificationService} />
          </div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.tryAgain.title"
              defaultMessage="Check Your Name & Email"
              tagName="h1"
            />
          </div>
          <div className="sid-header__subtitle sid-l-horz-center sid-header__subtitle--error">
            <FormattedHTMLMessage
              id="step.personalInfo.tryAgain.subtitle"
              defaultMessage="Your full name and email address must match what is on record with your school."
            />
          </div>
        </div>
      ) : (
        <div className="sid-header">
          <div className="sid-teacher-personal-info-logo-container sid-l-horz-center">
            <LogoComponent verificationService={verificationService} />
          </div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.title"
              defaultMessage="Unlock this Teaching Staff-Only Offer"
              tagName="h1"
            />
          </div>
          <div className="sid-header__subtitle sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.subtitle"
              defaultMessage="Verify you're a teacher, faculty, or staff at an accredited educational institution."
            />
          </div>
          <div className="sid-header__how-verifying-works sid-l-horz-center">
            <HowDoesVerifyingWork verificationService={verificationService} />
            <RewardsRemainingComponent verificationService={verificationService} />
          </div>
        </div>
      )}

      <ChangeLocaleV2Component verificationService={verificationService} />
      <CountryComponentWrapper verificationService={verificationService} />

      <TeacherSchoolComponent
        value={(verificationService.viewModel as TeacherPersonalInfoViewModel).organization}
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.organization}
        onChange={(choice: Organization) => {
          updateViewModelOrganization(choice, verificationService);
          updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
        }}
      />

      <div className="sid-names">
        <FirstName
          value={viewModel.firstName}
          isErrored={!!fieldValidationErrors.firstName}
          onChange={(newValue) => {
            updateTeacherViewModel("firstName", newValue);
            updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
          }}
        />

        <LastName
          value={viewModel.lastName}
          isErrored={!!fieldValidationErrors.lastName}
          onChange={(newValue) => {
            updateTeacherViewModel("lastName", newValue);
            updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
          }}
        />
      </div>

      {verificationService.programTheme.idCheckAddOnEnabled ? (
        <BirthDate
          isErrored={!!fieldValidationErrors.birthDate}
          errorId={fieldValidationErrors.birthDate}
          locale={locale}
          onChange={(newValue) => {
            updateTeacherViewModel("birthDate", newValue);
            updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
          }}
          value={viewModel.birthDate}
        />
      ) : null}

      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        explanation={
          <FormattedHTMLMessage
            id="emailExplanation"
            defaultMessage="Must be your school-issued email address"
          />
        }
        onChange={(newValue) => {
          updateTeacherViewModel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event) => handleEmailOnKeyDown(event)}
      />

      {verificationService.programTheme.isSmsNotifierConfigured ||
      verificationService.programTheme.smsLoopEnabled ? (
        <PhoneNumber
          isRequired={!!verificationService.programTheme.smsLoopEnabled}
          value={viewModel.phoneNumber}
          isErrored={!!fieldValidationErrors.phoneNumber}
          onChange={(newValue) => {
            updateTeacherViewModel("phoneNumber", newValue);
            updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
          }}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      ) : null}
      {shouldCollectPostalCode(verificationService.programTheme, viewModel) ? (
        <PostalCodeComponent
          isErrored={!!fieldValidationErrors.postalCode}
          onChange={(newValue) => {
            updateTeacherViewModel("postalCode", newValue);
            updateFieldValidationErrorsByFieldId("postalCode", newValue, verificationService);
          }}
          value={viewModel.postalCode}
        />
      ) : null}

      {getOptions().customFormFields.map((config: FormFieldConfig) => (
        <FormFieldCustom config={config} verificationService={verificationService} />
      ))}

      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updateTeacherViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <ExtraMessage verificationService={verificationService} suffix="CollectAboveSubmit" />

      <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
        <div className="sid-teacher-submit sid-submit">
          <button
            id="sid-submit-btn-collect-info"
            onClick={() =>
              submitForm(
                viewModel,
                verificationService,
                VerificationStepsEnum.collectTeacherPersonalInfo,
              )
            }
            type="submit"
            className={`sid-btn sid-teacher-submit-btn sid-btn--dark sid-l-full-width ${
              !isFormFilled(viewModel, verificationService.formValidationOptions)
                ? "sid-btn--disabled-like"
                : ""
            }`}
            aria-labelledby="verify-status-text"
            ref={(button) => setRef("submitButton", button)}
          >
            <span id="verify-status-text">
              {failedInstantMatch ? (
                <FormattedHTMLMessage id="tryAgain" defaultMessage="Try Again" />
              ) : (
                <FormattedHTMLMessage
                  id="verifyMyTeacherStatus"
                  defaultMessage="Verify My Teacher Status"
                />
              )}
            </span>
          </button>
        </div>
      </div>

      <FormFooter verificationService={verificationService} />
    </div>
  );
};

export const StepTeacherPersonalInfoComponent = StepTeacherPersonalInfo;
